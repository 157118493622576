// Libs
import styled from 'styled-components'

// Services
import {
  desktopMediaQuery,
  mobileMediaQuery,
  printMediaQuery,
  tabletMediaQuery,
} from '../../services/helper'

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${desktopMediaQuery(`
    padding-left: 40px;
    padding-right: 40px;
  `)}

  ${tabletMediaQuery(`
    padding-left: 32px;
    padding-right: 32px;
  `)}

  ${mobileMediaQuery(`
    padding-left: 9px;
    padding-right: 9px;
  `)}

  ${printMediaQuery(`
    padding-left: 0;
    padding-right: 0;
  `)}
`
