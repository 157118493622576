/** Libs */
import { YLink, YIcon, YIconButton } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Services */
import { emailCS, engagementUrl, websiteHT } from '../../services/constants'
import {
  FacebookHT,
  InstagramHT,
  LinkedinHT,
  YoutubeHT,
} from '../../services/constants'
import { getBlogURL } from '../../services/helper'

/** Images */
import LogoHT from '../../assets/svg/LogoHT'

/**  Components */
import CustomContainer from '../CustomContainer'
import NotPrintable from '../NotPrintable'

/** Styles */
import {
  StyledFooter,
  StyledContent,
  Title,
  Social,
  Guide,
  Support,
  Copyright,
  SupportGuide,
} from './styles'

const Footer: React.FC = () => {
  const { t: translateFooter, i18n } = useTranslation('Footer')
  const currentYear = new Date().getFullYear()

  const handleGoToSocial = (social: string) => {
    const socialLinks = {
      instagram: InstagramHT,
      facebook: FacebookHT,
      youtube: YoutubeHT,
      linkedin: LinkedinHT,
      blog: getBlogURL(i18n.language),
      email: emailCS,
    }

    const url = socialLinks[social as keyof typeof socialLinks]
    window.open(url, '_blank')
  }

  return (
    <NotPrintable>
      <div className="RainbowLoader"></div>
      <StyledFooter>
        <CustomContainer>
          <StyledContent>
            <div>
              <LogoHT />
              <Social>
                <YIconButton
                  aria-label={'Instagram'}
                  title={'Instagram'}
                  onClick={() => handleGoToSocial('instagram')}
                  children={<YIcon name="instagram" />}
                />
                <YIconButton
                  aria-label={'Facebook'}
                  title={'Facebook'}
                  onClick={() => handleGoToSocial('facebook')}
                  children={<YIcon name="facebook" />}
                />
                <YIconButton
                  aria-label={'Youtube'}
                  title={'Youtube'}
                  onClick={() => handleGoToSocial('youtube')}
                  children={<YIcon name="youtube" />}
                />
                <YIconButton
                  aria-label={'LinkedIn'}
                  title={'LinkedIn'}
                  onClick={() => handleGoToSocial('linkedin')}
                  children={<YIcon name="linkedin" />}
                />
                <YIconButton
                  aria-label={'Blog'}
                  title={'Blog'}
                  onClick={() => handleGoToSocial('blog')}
                  children={<YIcon name="history-edu" />}
                />
                <YIconButton
                  aria-label={'E-mail'}
                  title={translateFooter('emailAlt')}
                  onClick={() => handleGoToSocial('email')}
                  children={<YIcon name="email" />}
                />
              </Social>
              <Copyright>
                <span className="date">{currentYear}</span>
                <YLink
                  inline
                  size="small"
                  href={websiteHT}
                  target="_blank"
                  children={' Hand Talk'}
                />
                . {translateFooter('allRightsReserved')}
              </Copyright>
            </div>
            <div className="VerticalLine"></div>
            <SupportGuide>
              <Support className="FlexColumn">
                <Title>{translateFooter('anyQuestions')}</Title>
                <YLink
                  size="small"
                  target="_blank"
                  href={emailCS}
                  children={translateFooter('contactEmailText')}
                />
              </Support>
              <Guide className="FlexColumn">
                <Title>{translateFooter('engagementTitle')}</Title>
                <YLink
                  size="small"
                  href={engagementUrl}
                  target="_blank"
                  children={translateFooter('engagementLink')}
                />
              </Guide>
            </SupportGuide>
          </StyledContent>
        </CustomContainer>
      </StyledFooter>
    </NotPrintable>
  )
}

export default Footer
