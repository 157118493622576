import styled, { css } from 'styled-components'

interface ITitleProps {
  success: boolean
  error: boolean
}

export const Title = styled.h1<ITitleProps>`
  font: ${({ theme }) => theme.typography.title.md};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  text-align: center;
  margin: 0;
  padding: 0;

  ${({ theme, success }) =>
    success &&
    css`
      color: ${theme.colors.positive.default[0]};
    `}

  ${({ theme, error }) =>
    error &&
    css`
      color: ${theme.colors.error.default[0]};
    `}
`

export const Subtitle = styled.p<{ maxWidth?: boolean }>`
  max-width: ${({ maxWidth }) => (maxWidth ? 'none' : '408.74px')};
  text-align: center;
  font: ${({ theme }) => theme.typography.paragraph.lg};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
`

export const LogoWrapper = styled.h1`
  font: ${({ theme }) => theme.typography.title.md};
  margin: 0;
  padding: 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
`
