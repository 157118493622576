import styled from 'styled-components'

const Container = styled.main`
  background-color: ${({ theme }) => theme.colors.surface.dim.background[0]};
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
`

export default Container
