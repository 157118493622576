// Libs
import styled from 'styled-components'

// Services
import { tabletMediaQuery } from '../../services/helper'

// Tokens
import { font } from '../../assets/tokens/fonts'
import { feedback, highlight, neutral } from '../../assets/tokens/colors'

export const WrapperInvalidToken = styled.div`
  margin: 76px 0 72px;

  ${tabletMediaQuery(`
    margin: 48px 7px 120px;
  `)}

  .RainbowLoaderBorder {
    border-radius: 0px 0px 16px 16px;
  }

  .RaindowLoader {
    margin-top: -6.95px;
  }
`

export const ContainerAll = styled.section`
  min-height: 404px;
  padding: 0 153px;
  background: ${neutral.color.high.lightest};
  border-radius: 16px 16px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .TitleInvalidToken {
    font: ${font.heading.large};
    font-family: ${font.family};
    color: ${highlight.color.main};
    margin-bottom: 24px;
  }

  ${tabletMediaQuery(`
    padding: 24px 16px 0;

   .TitleInvalidToken {
      font: ${font.heading.medium};
      font-family: ${font.family};
    }
  `)}
`

export const Info = styled.div`
  margin: 0 40px;

  ${tabletMediaQuery(`
    margin: 0;
  `)}
`

export const StyledLink = styled.a`
  font: ${font.underline.medium};
  font-family: ${font.family};
  text-decoration-line: underline;
  color: ${feedback.color.info.main};
`
