/** Interfaces */
import { ICardHelper } from '../../services/interfaces'

/** Styles */
import { Section, TopCard, StyledIcon } from './styles'

/** Components */
import Title from '../Title'

const CardHelper: React.FC<ICardHelper> = ({
  children,
  title,
  icon,
  buttonHelp,
  noTopCardMargin,
  marginType,
  className,
}) => {
  return (
    <Section className={className} marginType={marginType}>
      <TopCard noTopCardMargin={noTopCardMargin}>
        <div className="AlignCenter">
          <StyledIcon>{icon}</StyledIcon>
          <Title tag={'h2'} className={'TitleWithIcon'} children={title} />
        </div>
        {buttonHelp}
      </TopCard>
      {children}
    </Section>
  )
}

export default CardHelper
