import SignIn from './SignIn'
import CreatePassword from './CreatePassword'
import Expired from './Expired'
import ResetPassword from './ResetPassword'

const AuthForms = {
  SignIn,
  CreatePassword,
  Expired,
  ResetPassword,
}

export default AuthForms
