/** Interfaces */
import { IUrlLinkProps } from '../../services/interfaces'

const formatRoute = (route: string) => {
  const len = route.length
  if (route[0] === '/') {
    return route.substring(1, len)
  }

  return route
}

const isRoutePossible = (domain: string, route: string) => {
  if (domain === '#' || route === '(not set)' || route === 'Não informado') {
    return false
  }

  return true
}

const UrlLink = (props: IUrlLinkProps) => {
  const { domain, route } = props

  const isPossible = isRoutePossible(domain, route)

  if (isPossible) {
    return (
      <a
        href={domain + formatRoute(route)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {route}
      </a>
    )
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a>{route}</a>
}

export default UrlLink
