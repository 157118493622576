export const font = {
  // FONT FAMILY
  family: `"Lato", sans-serif`,
  // FONT WEIGHT
  weight: {
    regular: 400,
    bold: 700,
    black: 900,
  },
  // FONT STYLES
  heading: {
    large: '900 32px/130% sans-serif',
    medium: '900 24px/130% sans-serif',
    small: '900 20px/130% sans-serif',
    Xsmall: '900 16px/130% sans-serif',
  },
  subtitle: {
    large: '700 32px/150% sans-serif',
    medium: '700 24px/150% sans-serif',
    small: '700 20px/150% sans-serif',
    Xsmall: '700 16px/150% sans-serif',
    X2s: '700 14px/150% sans-serif',
  },
  paragraph: {
    large: '400 20px/130% sans-serif',
    medium: '400 16px/130% sans-serif',
    small: '400 14px/130% sans-serif',
    Xsmall: '400 12px/130% sans-serif',
    X2small: '400 10px/130% sans-serif',
  },
  underline: {
    large: '400 20px/130% sans-serif',
    medium: '400 16px/130% sans-serif',
    small: '400 14px/130% sans-serif',
    Xsmall: '400 12px/130% sans-serif',
    X2small: '400 10px/130% sans-serif',
  },
  size: {
    small: '10px',
    normal: '14px',
  },
}
