/** Libs */
import { useTranslation } from 'react-i18next'

/** Services */
import { abbreviateMonth, getMonthlyInfo } from '../../services/helper'
import { Language } from '../../services/interfaces'
import { ITranslationHistory } from '../../services/interfaces'

interface IMonthly {
  forMonth: string
}

/** Obtém o somatório do historico de traduções */
const sumDays = (days: Record<string, number>) => {
  return Object.values(days).reduce((acc, value) => acc + value, 0)
}

const GetMonthlyTranslations = (
  translationHistory: ITranslationHistory,
  selectedLanguage: Language,
  language: string
): IMonthly[] => {
  const { t } = useTranslation('Chart')
  const dailyInfo =
    translationHistory.signLanguage[selectedLanguage]?.dailyInfo || {}
  const monthlyInfo = getMonthlyInfo(dailyInfo)

  const monthlyData = Object.entries(monthlyInfo).reduce<IMonthly[]>(
    (acc, [month, dailyInfo]) => {
      const translations = sumDays(dailyInfo)
      const displayMonth = abbreviateMonth(month + '01', language)

      acc.push({
        [t('translatedWordsTitle')]: translations,
        forMonth: displayMonth,
      })
      return acc
    },
    []
  )

  return monthlyData
}

export default GetMonthlyTranslations
