// Libs
import styled from 'styled-components'

// Services
import { printMediaQuery } from '../../services/helper'

export const Wrapper = styled.div`
  ${printMediaQuery(`
    visibility: hidden;
    display: none;
  `)}
`

export const PDFWrapper = styled.div`
  @media print {
    .recharts-wrapper,
    .recharts-surface,
    .recharts-legend-wrapper {
      width: 100% !important;
    }
  }
`
