import styled, { css } from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[3]};
  max-width: 300px;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[3]};
  max-width: 600px;
  width: 100%;
  text-align: center;
`

export const ErrorLabel = styled.span`
  font: ${({ theme }) => theme.typography.label.sm};
  color: ${({ theme }) => theme.colors.error.default[0]};
`

interface IPasswordTipProps {
  state: 'success' | 'error' | 'default'
}

export const PasswordTip = styled.span<IPasswordTipProps>`
  display: flex;
  align-items: center;
  font: ${({ theme }) => theme.typography.label.sm};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  * > path {
    fill: ${({ theme }) => theme.colors.surface.onSurface[0]};
  }

  ${({ theme, state }) =>
    state === 'error' &&
    css`
      color: ${theme.colors.error.default[0]};
      * > path {
        fill: ${({ theme }) => theme.colors.error.default[0]};
      }
    `}

  ${({ theme, state }) =>
    state === 'success' &&
    css`
      color: ${theme.colors.positive.default[0]};
      * > path {
        fill: ${({ theme }) => theme.colors.positive.default[0]};
      }
    `}
`

export const PasswordTipGroup = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
`
