export type EventDetails = {
  subtitle: string
  text: string
  direction: 'left' | 'right'
  double?: boolean
  url?: string
}

export type Events = {
  month: string
  events: EventDetails[]
}

export const highlights: Events[] = [
  {
    month: 'Janeiro',
    events: [
      {
        subtitle: 'Hand Talk Show',
        text: 'Empregabilidade para Pessoas com Deficiência',
        direction: 'right',
        url: 'https://www.youtube.com/watch?v=UkE5GflvTAc',
      },
    ],
  },

  {
    month: 'Março',
    events: [
      {
        subtitle: 'Dashboard 2.0',
        text: 'Novo visual Dashboard do cliente',
        direction: 'left',
      },
    ],
  },
  {
    month: 'Abril',
    events: [
      {
        subtitle: 'Hand Talk Show',
        text: 'Libras dentro das organizações: como implantar e a importância na cultura organizacional',
        direction: 'right',
        url: 'https://www.youtube.com/watch?v=j7pb_E0Sfds',
      },
    ],
  },
  {
    month: 'Maio',
    events: [
      {
        subtitle: 'Hand Talk Webinar GAAD',
        text: 'Pense, fale e aprenda sobre acessibilidade e inclusão digital',
        direction: 'left',
        url: 'https://acessibilidade.handtalk.me/webinar-gaad-2023',
      },
    ],
  },
  {
    month: 'Agosto',
    events: [
      {
        subtitle: 'Lançamento Plugin para ASL',
        text: 'Nosso plugin agora cobre a Língua Americana de Sinais!',
        direction: 'right',
      },
      {
        subtitle: 'Realização do Link 2023',
        text: 'Festival de Acessibilidade Digital',
        direction: 'left',
        double: true,
        url: 'https://www.youtube.com/playlist?list=PLqRFj_LQsbhyj0Ld3-UmbNYBmue5_yjL6',
      },
    ],
  },
  {
    month: 'Setembro',
    events: [
      {
        subtitle: 'Debates importantes',
        text: 'Realizamos o Webinar Diálogo Inclusivo',
        direction: 'right',
        url: 'https://drive.google.com/file/d/11YJId8OEXr1bfujLYqbMJ8XZf2EPb6ax/view',
      },
    ],
  },
  {
    month: 'Outubro',
    events: [
      {
        subtitle: 'Acessibilidade Digital Brasil',
        text: 'Resultados da pesquisa Panorama Acessibilidade Digital Brasil - Parte 1',
        direction: 'left',
        url: 'https://acessibilidade.handtalk.me/e-book-estudo-panorama-acessibilidade-digital-pcds',
      },
      {
        subtitle: 'Nova métrica no Dashboard',
        text: 'Adicionamos a métrica de Usuários por região',
        direction: 'right',
        double: true,
      },
      {
        subtitle: 'Novidade para o app mobile',
        text: 'Lançamento Leitor de texto para dispositivos móveis',
        direction: 'left',
        double: true,
      },
      {
        subtitle: 'Conquista',
        text: 'Prêmio Top3 Open Startups - Inteligência Artificial',
        direction: 'right',
        double: true,
        url: 'https://www.openstartups.net/site/ranking/rankings-categories.html',
      },
    ],
  },
  {
    month: 'Novembro',
    events: [
      {
        subtitle: 'Acessibilidade Digital Brasil',
        text: 'Resultados da pesquisa Panorama Acessibilidade Digital Brasil - Parte 2',
        direction: 'left',
        url: 'https://acessibilidade.handtalk.me/e-book-estudo-panorama-acessibilidade-digital-especialistas',
      },
      {
        subtitle: 'Lançamento de novas funcionalidades',
        text: 'Modo Leitura, Leitor de Sites, Aumentar Texto, Espaçamento de texto',
        direction: 'right',
        double: true,
      },
    ],
  },
  {
    month: 'Dezembro',
    events: [
      {
        subtitle: 'Mais uma métrica para o Dashboard',
        text: 'Adicionamos a métrica de Usuários por dispositivo',
        direction: 'left',
      },
    ],
  },
]
