/** Libs */
import { useTranslation } from 'react-i18next'
import { YHelpButton, YIcon } from '@hand-talk/yotta-components'

/** Components */
import Table from '../Table'
import CustomContainer from '../CustomContainer'
import CardHelper from '../CardHelper'
import Title from '../Title'

/** Services */
import { ITableItem } from '../../services/interfaces'
import { translationsHelper } from '../../services/TranslationsHelper'
import { isMobile } from '../../services/helper'

interface IITopAddonse {
  data: ITableItem[]
  period: string
}

const TopAddons: React.FC<IITopAddonse> = ({ data, period }) => {
  const { t: translateTopAddons, i18n } = useTranslation('TopAddons')

  const translateDataItem = (item: ITableItem): ITableItem => ({
    ...item,
    source: translationsHelper(item.source, 'topAddons', i18n.language),
  })

  const translatedData = data.map(translateDataItem)

  return (
    <CustomContainer className="PrintOnly">
      <Title
        tag={'h2'}
        className={'TitleAll'}
        children={translateTopAddons('title')}
      />
      <CardHelper
        title={translateTopAddons('subtitle')}
        icon={<YIcon name={'accessibility'} />}
        buttonHelp={
          <YHelpButton
            content={translateTopAddons('helpButtonContent', {
              period,
            })}
            direction={isMobile() === true ? 'left' : 'bottom'}
            context={'dark'}
            display={'desktop'}
            alignment={'center'}
          />
        }
      >
        <Table
          data={translatedData}
          headers={[
            { id: 'EmptyTopAddons', text: () => '', className: 'mobile' },
            {
              id: 'positionTopAddons',
              text: () => translateTopAddons('position'),
              className: 'desktop',
            },
            {
              id: 'resources',
              text: () => translateTopAddons('resources'),
            },
            {
              id: 'actions',
              text: () => translateTopAddons('actions'),
            },
          ]}
        ></Table>
      </CardHelper>
    </CustomContainer>
  )
}

export default TopAddons
