import styled from 'styled-components'
import { mobileMediaQuery } from '../../services/helper'

export const SmallDataCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: fit-content;
  padding: 2px 12px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.surface.dim[2][0]};

  ${mobileMediaQuery(`
    width: 100%;
  `)}
`

export const Text = styled.p`
  font: ${({ theme }) => theme.typography.label.sm};
  color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
`

export const Value = styled.p`
  font: ${({ theme }) => theme.typography.title.lg};
  color: ${({ theme }) => theme.colors.secondary.default[0]};
`
