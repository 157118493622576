import React from 'react'

import { startOfDay } from '../../services/utils'
import { Language } from '../../services/interfaces'
import { Timestamp } from '../../services/Timestamp'

type Props = {
  selected?: [Timestamp, Timestamp]
  language?: Language
  onSelect: (nextSelected: [Timestamp, Timestamp]) => void
  onDisableDay?: (dayTimestamp: Timestamp) => boolean
  isAverageReport: boolean
}

export const useDatepickerRange = (props: Props) => {
  const { selected, onSelect, onDisableDay } = props

  const [nextSelected, setNextSelected] = React.useState<Timestamp[]>(() => {
    if (!selected) {
      return []
    }

    return selected.map(startOfDay).sort()
  })

  const [highlightedDay, setHighlightedDay] = React.useState<Timestamp>(
    0 as Timestamp
  )

  const selectedCountRef = React.useRef(nextSelected)
  selectedCountRef.current = nextSelected

  const onClick = React.useCallback(
    (dayTimestamp: Timestamp) => {
      if (selectedCountRef.current.length === 1) {
        const finalNextSelected = [
          selectedCountRef.current[0],
          dayTimestamp,
        ].sort() as [Timestamp, Timestamp]

        setNextSelected(finalNextSelected)
        onSelect(finalNextSelected)
        setHighlightedDay(0 as Timestamp)
        return
      }

      setNextSelected([dayTimestamp])
      setHighlightedDay(dayTimestamp)
    },
    [onSelect]
  )

  const onHighlight = React.useCallback(
    (dayTimestamp: Timestamp) => {
      const isDisabled = onDisableDay && onDisableDay(dayTimestamp)

      if (isDisabled || selectedCountRef.current.length !== 1) {
        return
      }

      setHighlightedDay(dayTimestamp)
    },
    [onDisableDay]
  )

  React.useEffect(() => {
    if (selected) {
      setNextSelected(selected.map(startOfDay).sort())
    }
  }, [selected])

  return {
    nextSelected,
    highlightedDay,
    onClick,
    onHighlight,
  }
}
