/** Libs */
import { useTranslation } from 'react-i18next'

/** Services */
import {
  formatDateWithCustomTemplate,
  formatPeriodToDate,
  getTemplate,
} from '../../services/helper'

import { ITranslationHistory, Language } from '../../services/interfaces'

interface IAccumulativeData {
  day: string
}

const GetDataAcumulative = (
  translationHistory: ITranslationHistory,
  selectedLanguage: Language,
  language: string
): IAccumulativeData[] => {
  let total = 0
  const dailyInfo =
    translationHistory.signLanguage[selectedLanguage]?.dailyInfo || {}

  const { t } = useTranslation('Chart')

  const dataAcumulative = Object.entries(dailyInfo).map(([key, value]) => {
    total += value

    const template = getTemplate(language)
    const day = formatDateWithCustomTemplate(formatPeriodToDate(key), template)

    return {
      day,
      [t('translatedWordsTitle')]: total,
    }
  })

  return dataAcumulative
}

export default GetDataAcumulative
