/** Libs */
import { YLink } from '@hand-talk/yotta-components'

/** Components */
import CustomContainer from '../CustomContainer'
import Title from '../Title'

/** Styles */
import {
  StyleLine,
  StyleCard,
  Section,
  Month,
  Row,
  Events,
  SubTitle,
  Text,
  Space,
} from './styles'

import { highlights } from './highlights'

export interface TimeLine {
  subtitle: string
  text: string
  month: string
  direction: 'left' | 'right'
  double?: boolean
  url?: string
}

const Line: React.FC<{ double?: boolean }> = ({ double }) => (
  <StyleLine className="FlexColumn" double={double}>
    <div className="gray line"></div>
    <div className="gray circle"></div>
    <div className="gray line"></div>
  </StyleLine>
)

const Card: React.FC<TimeLine> = ({
  month,
  subtitle,
  text,
  direction,
  double,
  url,
}) => {
  return (
    <StyleCard direction={direction}>
      <Space></Space>
      <Row className="FlexColumn">
        <Month double={double}>{month}</Month>
        <Line double={double} />
      </Row>
      <Events noUrl={!url}>
        <SubTitle>{subtitle}</SubTitle>
        <Text>{text}</Text>
        <YLink size="medium" href={url} children="Saiba Mais" />
      </Events>
      <div className="gray LineMobile"></div>
    </StyleCard>
  )
}

const Timeline: React.FC = () => {
  return (
    <CustomContainer>
      <Section>
        <Title
          tag={'h3'}
          className={'TitleTimeline'}
          children={'Vamos relembrar os principais momentos de 2023'}
        />
        {highlights.map((highlight) => (
          <div className="FlexColumn" key={highlight.month}>
            {highlight.events.map((event, index) => (
              <Card
                key={`${highlight.month}-${index}`}
                subtitle={event.subtitle}
                text={event.text}
                month={highlight.month}
                direction={event.direction}
                double={event.double}
                url={event.url}
              />
            ))}
          </div>
        ))}
      </Section>
    </CustomContainer>
  )
}

export default Timeline
