/** Libs */
import styled from 'styled-components'

/** Tokens */
import { highlight } from '../../assets/tokens/colors'
import { mobileMediaQuery, printMediaQuery } from '../../services/helper'

const commonStyles = `
flex-direction: column;

.divider {
  margin: 40px 0;
  width: 107px;
  height: 1px;
}
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0;

  .divider {
    width: 1px;
    height: 107px;
    margin: 0 40px;
    background-color: ${highlight.color.new};
  }

  ${mobileMediaQuery(commonStyles)}
  ${printMediaQuery(commonStyles)}
`

interface IColumn {
  width: number
}

export const Column = styled.div<IColumn>`
  width: ${(props) => props.width}px;

  ${printMediaQuery(`
    width: 100%;
  `)}
`
