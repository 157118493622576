// Libs
import axios, { AxiosError, AxiosHeaders } from 'axios'
import { inject, injectable } from 'inversify'

// Apis
import Config from './Config'

@injectable()
export default class DashboardApi {
  @inject(Config) config: Config

  public get = async <T>(
    path: string,
    params: Record<string, any>,
    headers: Partial<AxiosHeaders> = {}
  ) => {
    try {
      const axiosUrl = `${this.config.apiBaseUrl}/${path}`
      const axiosHeaders = new AxiosHeaders()

      axiosHeaders.set(headers)
      axiosHeaders.set('Content-Type', 'application/json')

      const response = await axios.get(axiosUrl, {
        params,
        headers: axiosHeaders,
      })
      return response.data as T
    } catch (error) {
      console.log(error)
      const { message, response } = error as AxiosError<string>
      throw new Error(response?.data || message)
    }
  }

  public post = async <T>(
    path: string,
    params: Record<string, any>,
    headers: Partial<AxiosHeaders> = {}
  ): Promise<T> => {
    try {
      const axiosUrl = `${this.config.apiBaseUrl}/${path}`
      const axiosHeaders = new AxiosHeaders()

      axiosHeaders.set(headers)
      axiosHeaders.set('Content-Type', 'application/json')

      const response = await axios.post(axiosUrl, params, {
        headers: axiosHeaders,
      })

      return response.data as T
    } catch (error) {
      console.log(error)
      const { message, response } = error as AxiosError<string>
      throw new Error(response?.data || message)
    }
  }
}
