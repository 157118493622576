import styled, { keyframes } from 'styled-components'

/** Services */
import { mobileMediaQuery } from '../../../services/helper'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(16px);
  }
`
/** Camada atrás do calendário, que fica por cima do conteúdo da página */
export const Backdrop = styled.div<{ shown: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 500;
  animation: ${({ shown }) => (shown ? fadeIn : fadeOut)} 0.3s ease-out;
`

/** Posicionamento do calendário */
export const Wrapper = styled.div<{ shown: boolean }>`
  position: absolute;
  z-index: 700;
  top: 60px;
  left: 0;
  animation: ${({ shown }) => (shown ? fadeIn : fadeOut)} 0.3s ease-out;

  ${mobileMediaQuery(`
    top: 0;
  `)}
`

export const WrapperDatepickerPopup = styled.div`
  z-index: 100;
  max-width: 370px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadow[3]};

  ${mobileMediaQuery(`
    max-width: 314px;
  `)}
`
