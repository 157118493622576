interface IAuthUserSchema {
  displayName: string
  email: string
}

export default class AuthUser implements IAuthUserSchema {
  displayName: string
  email: string

  static fromObject(obj: IAuthUserSchema): AuthUser {
    return Object.assign(new AuthUser(), obj)
  }
}
