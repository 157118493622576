/** Interfaces */
import { ITopContainer } from '../../services/interfaces'

/** Components */
import CustomContainer from '../CustomContainer'

/** Styles */
import { Container, Wrapper } from './styles'

import Menu from '../Menu'

const TopContainer: React.FC<ITopContainer> = ({
  filter,
  onClickExportCsv,
  onClickExportPdf,
  domain,
}) => {
  return (
    <Wrapper>
      <CustomContainer>
        <div className="OnlyPrint">Domínio: {domain}</div>
        <Container>
          {filter}
          <Menu.Export
            onClickExportCsv={onClickExportCsv}
            onClickExportPdf={onClickExportPdf}
          />
        </Container>
      </CustomContainer>
    </Wrapper>
  )
}

export default TopContainer
