/** Libs */
import { YLink } from '@hand-talk/yotta-components'

/** Styles */
import { Card, Section, Image, List, ContentTitle } from './styles'

/** Components */
import CustomContainer from '../CustomContainer'
import CustomText from '../CustomText'
import Title from '../Title'
import UrlLink from '../Table/UrlLink'

/** Tokens */
import { neutral } from '../../assets/tokens/colors'

/** Services */
import { IReportSchema } from '../../services/interfaces'
import { formatDashboardValue, formatDomain } from '../../services/helper'

type TResume = {
  reportYearly?: IReportSchema
}

const Resume: React.FC<TResume> = ({ reportYearly }) => {
  if (!reportYearly) return null

  const {
    translations: { translatedWords },
    insight: { topPages, users },
    info: { domain },
  } = reportYearly

  const translationsFormatted = formatDashboardValue(translatedWords).formatted
  const usersFormatted = formatDashboardValue(users).formatted
  const formattedDomain = formatDomain(domain)

  return (
    <CustomContainer>
      <Section>
        <ContentTitle className="FlexColumn">
          <Title
            tag={'h2'}
            className={'TitleResume'}
            children={'Aqui está um resumo de 2023'}
          />
          <YLink size="big" href={formattedDomain} children={domain} />
        </ContentTitle>
        <div className="FlexCenterColumnTablet">
          <div>
            <Card className="CardTranslations" color="#6251DA" padding={'16px'}>
              <Image
                src="/focus.png"
                alt="Ícone de alvo com círculos vermelhos e branco, no centro do alvo há uma flecha acertada."
              />
              <div>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={900}
                  size={24}
                  height={26}
                >
                  Juntos, traduzimos
                </CustomText>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={900}
                  size={40}
                  height={52}
                >
                  {translationsFormatted}
                </CustomText>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={400}
                  size={20}
                  height={26}
                >
                  palavras
                </CustomText>
              </div>
            </Card>
            <Card color="#089B85" padding={'16px'}>
              <Image
                src="/solidarity.png"
                alt="Ícone de braços entrelaçados formando um circulo, cada braço tem uma tonalidade de pele."
              />
              <div>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={900}
                  size={24}
                  height={26}
                >
                  Eu e você, ajudamos
                </CustomText>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={900}
                  size={40}
                  height={52}
                >
                  {usersFormatted}
                </CustomText>
                <CustomText
                  color={neutral.color.high.lightest}
                  weight={400}
                  size={20}
                  height={26}
                >
                  pessoas
                </CustomText>
              </div>
            </Card>
          </div>
          <Card className="CardPages" color="#1473B5" padding={'24px 24px 0'}>
            <Image
              height={264}
              src="/Hugo.png"
              alt="Imagem de Hugo, avatar 3D, de um homem branco, com cabelos curtos, lisos e pretos, está usando uma camisa branca de botões, uma gravata azul e calças jeans. Sua postura é relaxada e confiante, com uma mão apoiada na cintura e a outra pendendo ao lado do corpo"
              below={true}
            />
            <div>
              <CustomText color={'#FFE0A8'} weight={900} size={24} height={31}>
                Top 3 páginas mais acessadas
              </CustomText>
              <ol type="1">
                {topPages.slice(0, 3).map((item, index) => (
                  <List key={index}>
                    <CustomText
                      color={'#FFE0A8'}
                      weight={900}
                      size={32}
                      height={41.6}
                    >{`${index + 1}º`}</CustomText>
                    <UrlLink domain={formattedDomain} route={item.source} />
                  </List>
                ))}
              </ol>
            </div>
          </Card>
        </div>
      </Section>
    </CustomContainer>
  )
}
export default Resume
