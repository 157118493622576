/** Libs */
import styled from 'styled-components'

/** Services */
import {
  desktopMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '../../services/helper'

export type IconProps = {
  size: string
  iconColor: string
}

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
`

export const MenuContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
`

export const DomainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 16px 0;

  ${desktopMediaQuery(`
    padding-left: 40px;
    padding-right: 40px;
  `)}

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }

  ${tabletMediaQuery(`
    padding-left: 32px;
    padding-right: 32px;
  `)}

  ${mobileMediaQuery(`
    padding-left: 9px;
    padding-right: 9px;
  `)}
`

export const UpperContainer = styled.div`
  display: flex;
  max-width: 480px;
  width: 100%;
  gap: 16px;

  @media (max-width: 900px) {
    padding-bottom: 16px;
  }

  ${mobileMediaQuery(`
    flex-direction: column;
  `)}
`

export const LowerContainer = styled.div`
  display: flex;
  max-width: 380px;

  ${mobileMediaQuery(`
    max-width: 100%;
    width: 100%;
  `)}
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LogoWrapper = styled.div`
  width: 140px;
  height: 32px;

  svg {
    height: 100%;
    width: 100%;
  }
`

export const ContainerDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
  display: flex;
  justify-content: center;
`

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.border.low[0]};
  max-width: 1280px;
  width: 100%;
  height: 1px;

  ${desktopMediaQuery(`
   margin-left: 40px;
   margin-right: 40px;
  `)}

  ${tabletMediaQuery(`
    margin-left: 32px;
    margin-right: 32px;
  `)}

  ${mobileMediaQuery(`
    margin-left: 9px;
    margin-right: 9px;
  `)}
`

export const H1 = styled.h1`
  font: ${({ theme }) => theme.typography.title.lg};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
`
