export const border = {
  /* BORDER RADIUS */
  radius: {
    mini: '6px',
    md: '12px',
    xd: '22px',
    circular: '50%',
  },
  /* BORDER WIDTH */
  width: {
    thin: '2px',
  },
  /* BORDER NONE */
  none: 'none',
}
