/** Libs */
import React from 'react'

/** Components */
import NotPrintable from '../NotPrintable'

/** Styles */
import { ContainerDivider, Divider, MenuContainer } from './styles'

interface INavbarProps {
  children: React.ReactNode
}

const Menu: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  return (
    <NotPrintable>
      <MenuContainer>{children}</MenuContainer>
      <ContainerDivider>
        <Divider />
      </ContainerDivider>
    </NotPrintable>
  )
}

export default Menu
