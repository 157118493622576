/** Libs */
import React from 'react'

/** Components */
import CustomContainer from '../CustomContainer'
import LogoHT from '../../assets/svg/LogoHT'

/** Styles */
import { NavContainer, ButtonsContainer, LogoWrapper } from './styles'

interface INavbarProps {
  children?: React.ReactNode
}

const Navbar: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  return (
    <>
      <NavContainer>
        <CustomContainer className="SpaceBetween">
          <LogoWrapper>
            <LogoHT />
          </LogoWrapper>
          <ButtonsContainer className="NotPrint">{children}</ButtonsContainer>
        </CustomContainer>
      </NavContainer>
      <div className="RainbowLoader"></div>
    </>
  )
}

export default Navbar
