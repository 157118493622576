/** Helpers */
import {
  abbreviateMonth,
  numberFormatter,
  transformMonthlyUsersData,
} from '../../services/helper'

/** Libs */
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

/** Services */
import { ptBrNumberFormatter } from '../../services/helper'
import { PDFWrapper } from '../NotPrintable/style'

interface IAccumulativeUsers {
  monthlyUsersInfo: { [key: string]: number }
  translation: Function
  language: string
}

export const Acumulative: React.FC<IAccumulativeUsers> = ({
  monthlyUsersInfo,
  translation,
  language,
}) => {
  const usersData = transformMonthlyUsersData(monthlyUsersInfo)

  return (
    <PDFWrapper>
      <ResponsiveContainer width="100%" height={448}>
        <LineChart
          data={usersData}
          margin={{
            top: 40,
            right: 20,
            left: 0,
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="forMonth"
            tickFormatter={(value: string) => abbreviateMonth(value, language)}
            tick={{
              fontSize: 12,
              fill: '#292929',
              fontFamily: 'Lato',
              fontWeight: 400,
            }}
            tickLine={{ stroke: '#656565', strokeWidth: 2 }}
            tickSize={15}
            padding={{ left: 20, right: 50 }}
            axisLine={{ stroke: '#656565', strokeWidth: 2 }}
            angle={45}
            textAnchor="end"
            dx={20}
            dy={40}
          />
          <YAxis
            tickFormatter={(value: string) => numberFormatter(value) as string}
            tick={{
              fontSize: 12,
              fill: '#292929',
              fontFamily: 'Lato',
              fontWeight: 400,
            }}
          />
          <Tooltip
            formatter={(value) => ptBrNumberFormatter(value)}
            labelFormatter={(label) => abbreviateMonth(label, language)}
          />
          <Legend />
          <Line
            dataKey="users"
            name={translation('users')}
            legendType="none"
            stroke={'#C64F01'}
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </PDFWrapper>
  )
}
