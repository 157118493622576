// Libs
import { useCallback, useState } from 'react'

// Domain
import AuthUser from '../../domain/entities/AuthUser'

// Main
import useCreateUserPasswordUseCase from '../use-cases/useCreateUserPasswordUseCase'
import useDecodeAuthTokenUseCase from '../use-cases/useDecodeAuthTokenUseCase'

interface IFirstAccessResult {
  type: 'loading' | 'success'
}

interface IFirstAccessDecoded {
  type: 'decoded'
  authUser: AuthUser
}

interface IFirstAccessPasswordError {
  type: 'password-error'
  message: string
}

interface IFirstAccessTokenError {
  type: 'token-error'
  message: string
}

type FirstAccessState =
  | IFirstAccessResult
  | IFirstAccessDecoded
  | IFirstAccessPasswordError
  | IFirstAccessTokenError

export default function useFirstAccessViewModel() {
  const decodeAuthTokenUseCase = useDecodeAuthTokenUseCase()
  const createUserPasswordUseCase = useCreateUserPasswordUseCase()

  const [token, setToken] = useState<string>()
  const [state, setState] = useState<FirstAccessState>({
    type: 'loading',
  })

  const decodeToken = useCallback(
    async (token: string) => {
      try {
        const authUser = await decodeAuthTokenUseCase.execute(token)
        setState({ type: 'decoded', authUser })
        setToken(token)
      } catch (error) {
        const { message } = error as Error
        setState({ type: 'token-error', message })
      }
    },
    [decodeAuthTokenUseCase]
  )

  const createPassword = useCallback(
    async (password: string) => {
      try {
        if (state.type !== 'decoded' || !token) {
          throw new Error('Falha ao criar senha, token não decodificado!')
        }

        const { authUser } = state

        setState({ type: 'loading' })
        await createUserPasswordUseCase.execute(token, authUser.email, password)
        setState({ type: 'success' })
      } catch (error) {
        const { message } = error as Error
        setState({ type: 'password-error', message })
      }
    },
    [createUserPasswordUseCase, state, token]
  )

  return { createPassword, decodeToken, state }
}
