// Libs
import {
  parse,
  endOfMonth,
  subMonths,
  format,
  startOfMonth,
  lastDayOfMonth,
  subYears,
} from 'date-fns'
import { IInfo, IPeriod, Language } from './interfaces'
import { Timestamp } from './Timestamp'

/**
 * Obtém o periodo disponível para filtrar o relatório
 */
export const getDefaultFilterPeriod = (info: IInfo): IPeriod => {
  const today = new Date()
  const creationDate = parse(info.subscriptionCreationDate, 'yyyy-MM-dd', today)
  const endDate = endOfMonth(subMonths(today, 1))

  const oneYearAgo = subYears(endDate, 1)
  const startDate = Math.max(creationDate.getTime(), oneYearAgo.getTime())

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  }
}

/**
 * Obtém o periodo disponível para filtrar o relatório
 */
export const getAverageFilterPeriod = (info: IInfo): IPeriod => {
  const today = new Date()
  const creationDate = parse(info.subscriptionCreationDate, 'yyyy-MM-dd', today)
  const endDate = endOfMonth(subMonths(today, 1))
  const startOfSnapshots = parse('2022-03-01', 'yyyy-MM-dd', today)
  const startDate = Math.max(creationDate.getTime(), startOfSnapshots.getTime())

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  }
}

/**
 * Obtém o periodo equivalente ao ultimo mês
 */
export const getLastMonthPeriod = (): IPeriod => {
  const today = new Date()
  const lastMonth = subMonths(today, 1)
  const startDate = startOfMonth(lastMonth)
  const endDate = endOfMonth(lastMonth)

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  }
}

/**
 * Obtém a língua oral de uma par de línguas (ex: ptBR-bzs -> pt)
 */
export const getOralLanguage = (language: Language) => {
  const languageMap: Record<Language, string> = {
    'en-ase': 'en',
    'ptBR-bzs': 'pt',
  }
  return languageMap[language] || 'pt'
}

/**
 * Obtém o par de línguas de uma língua oral (ex: pt -> ptBR-bzs)
 */
export const getLanguagePair = (language: string) => {
  const languageMap: Record<string, Language> = {
    en: Language['en-ase'],
    pt: Language['ptBR-bzs'],
  }
  return languageMap[language] || 'ptBR-bzs'
}

/** Retorna a maior e menor data */
export const checkInRange = (target: number, range: number[]): boolean => {
  const [min = 0, max = 0] = range

  return target >= min && target <= max
}

/** Retorna o início do dia contendo a data */
export const startOfDay = (dayTimestamp: Timestamp): Timestamp => {
  const date = new Date(dayTimestamp)
  return date.setHours(0, 0, 0, 0) as Timestamp
}

/** Retorna o último dia do mês */
export const lastDateOfMonth = (dayTimestamp: Timestamp): number => {
  const date = new Date(dayTimestamp)
  const lastDay = lastDayOfMonth(date)
  return lastDay.getDate()
}

/**
 * Calcula o valor de tempo a partir de uma data no formato de string.
 * @param date A data no formato de string.
 * @returns O valor de tempo correspondente em formato `Timestamp`.
 */
export const calculateTime = (date: string): Timestamp => {
  const time = new Date(date).getTime() + 86400000
  return time as Timestamp
}
