/** Libs */
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import { YGlobalStyle, YThemeProvider, lightTheme } from '@hand-talk/yotta-core'
import { useInjection } from 'inversify-react'

/** Services */
import { usePreferencies } from './hooks/usePreferencies'
import { setLanguage } from './i18n'
import * as analytics from './services/analytics'
import Config from './apis/Config'

/** Styles */
import { GlobalStyle } from './assets/styles/GlobalStyle'

/** Pages */
import ClientDashboard from './pages/ClientDashboard'
import NotFound from './pages/NotFound'
import Retrospective from './pages/Retrospective'
import Auth from './pages/Auth'
import FirstAccess from './pages/FirstAccess'
import DomainList from './pages/DomainList'
import ResetPassword from './pages/ResetPassword'
import NewPassword from './pages/NewPassword'
import AuthHandle from './pages/AuthHandle'

const App = () => {
  const { language, noAnalytics } = usePreferencies()
  const config = useInjection(Config)

  const RedirectToNotFound = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
      navigate('/not-found', { replace: true })
    }, [navigate])

    return null
  }

  React.useEffect(() => {
    if (noAnalytics) return

    analytics.init(config.ga4Tag)
  }, [config.ga4Tag, noAnalytics])

  React.useEffect(() => {
    setLanguage(language)
  }, [language])

  return (
    <YThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <YGlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/:accessKey" element={<ClientDashboard />} />
          <Route path="/retrospective/:accessKey" element={<Retrospective />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/first-access" element={<FirstAccess />} />
          <Route path="/domain-list" element={<DomainList />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/auth-handle" element={<AuthHandle />} />
          <Route path="*" element={<RedirectToNotFound />} />
        </Routes>
      </Router>
    </YThemeProvider>
  )
}

export default App
