import React, { ElementType } from 'react'

interface IFlexProps {
  as?: ElementType
  children?: React.ReactNode
  gap?: number | string
  alignItems?: string
  direction?: string
}

export default function Flex({
  as: Tag = 'div',
  gap = 0,
  direction = 'row',
  alignItems = 'center',
  children,
}: IFlexProps) {
  return (
    <Tag
      style={{
        display: 'flex',
        flexDirection: direction,
        alignItems,
        gap,
      }}
    >
      {children}
    </Tag>
  )
}
