// Libs
import { httpsCallable } from 'firebase/functions'
import { inject, injectable } from 'inversify'

// Models
import UserInfoModel, { IUserInfoSchema } from '../models/UserInfoModel'

// Depdendencies
import FirebaseApi from '../apis/FirebaseApi'

@injectable()
export default class UserInfoRepository {
  @inject(FirebaseApi) firebaseProvider: FirebaseApi

  public get = async (): Promise<UserInfoModel> => {
    const { functions } = this.firebaseProvider
    const getUserInfo = httpsCallable<unknown, IUserInfoSchema>(
      functions,
      'getUserInfo'
    )
    const result = await getUserInfo()

    return new UserInfoModel(result.data)
  }
}
