// Styles
import { Container } from './styles'

// Interfaces
type TContainer = {
  children: React.ReactNode
  className?: string
  styles?: React.CSSProperties
}

const CustomContainer: React.FC<TContainer> = ({
  children,
  className,
  styles,
}): JSX.Element => {
  return (
    <Container style={styles} className={className}>
      {children}
    </Container>
  )
}

export default CustomContainer
