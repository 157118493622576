import { IAccessKey, IInfo } from '../services/interfaces'

export interface IPartialReport {
  accessKey: IAccessKey
  info: IInfo
}

export interface IUserInfoSchema {
  language: 'ptBR' | 'en'
  displayName: string
  reports: IPartialReport[]
}

export default class UserInfoModel {
  constructor(private schema: IUserInfoSchema) {}

  public get reports() {
    return this.schema.reports
  }
}
