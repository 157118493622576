/** Libs */
import React from 'react'
import { YButton, YIcon } from '@hand-talk/yotta-components'

/** Components */
import CustomContainer from '../CustomContainer'
import NotPrintable from '../NotPrintable'

/** Styles */
import { Text } from './styles'

const Sharing: React.FC = () => {
  const printPDF = (): void => {
    const win: Window | null = window
    if (!win) return
    win.focus()
    win.print()
  }

  return (
    <NotPrintable>
      <CustomContainer>
        <div className="FlexColumn">
          <Text>
            Mostre ao mundo o quanto você ajudou na acessibilidade digital no
            ano de 2023!
          </Text>
          <YButton
            icon={<YIcon name="download" />}
            role="button"
            size="fitContent"
            onClick={printPDF}
            children=" Exportar retrospectiva"
          />
        </div>
      </CustomContainer>
    </NotPrintable>
  )
}

export default Sharing
