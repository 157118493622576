import styled from 'styled-components'

/** Services */
import { tabletMediaQuery } from '../../services/helper'

export const Container = styled.section`
  margin: 40px 0 115px;

  .TitleDomainList {
    margin-bottom: 32px;
    font: ${({ theme }) => theme.typography.title.lg};
  }
`

export const StyledListItem = styled.li`
  background: ${({ theme }) => theme.colors.surface.dim[1][0]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadow[3]};

  margin-bottom: 16px;
  padding: 24px 16px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${tabletMediaQuery(`
    flex-direction: column;
    align-items: flex-start;
  `)}
`
