import styled from 'styled-components'

/** Services */
import { mobileMediaQuery } from '../../services/helper'

export const ContainerStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[1]};
  margin: 8px 0;

  ${mobileMediaQuery(`
    flex-direction: column;
    align-items: flex-start;
  `)}
`

export const StyledTitle = styled.div`
  font: ${({ theme }) => theme.typography.label.mdBold};
  color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
  display: flex;
  align-items: center;
`

export const ButtonStyle = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;
  height: 48px;
  padding: 0 ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[3]};
  gap: ${({ theme }) => theme.spacing[3]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font: ${({ theme }) => theme.typography.label.md};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};

  background-color: ${({ isOpen }) =>
    isOpen
      ? '#d9d9d9'
      : ({ theme }) => theme.colors.surface.bright.background[0]};

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #d9d9d9;
  }

  &:focus {
    outline: 2px solid #c64f01;
    outline-offset: 2px;
  }
`

export const StyledTag = styled.span`
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme }) => theme.colors.secondary.container[0]};
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
  font: ${({ theme }) => theme.typography.label.smBold};
  margin-left: 4px;

  ${mobileMediaQuery(`
     margin-left: 0;
  `)}
`

export const IconWrapper = styled.span<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '270deg' : '90deg')});
  transition: transform 0.3s ease;

  > svg {
    color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
  }
`
