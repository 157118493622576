/**
 * Aguarda por um evento de um elemento
 * @param elem - Elemento que deverá ser observado
 * @param eventName - Nome do evento
 */
export const waitForEvent = (
  elemOrWindow: HTMLElement | Window,
  eventName: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    elemOrWindow.addEventListener(eventName, resolve, { once: true })
    setTimeout(() => {
      return reject('Falha ao aguardar evento, timeout!')
    }, 30000)
  })
}

/**
 * Injeta um script na página
 * @param elem - Elemento que receberá o script
 */
export const injectScript = async (
  elem: HTMLElement,
  src: string
): Promise<any> => {
  // Cria o elemento do script
  const script: HTMLScriptElement = document.createElement('script')
  script.src = src
  elem.appendChild(script)

  // Aguarda o carregamento do script
  try {
    await waitForEvent(script, 'load')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
