// TODO: Necessário adicionar o loading

// Libs
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Hooks
import useResetPassword from '../../hooks/useResetPassword'

// Components
import AuthForms from '../../components/AuthForms'
import Container from '../../components/Container'
import Loading from '../../components/Loading'

const NewPassword = () => {
  const { confirmResetPassword, resetPasswordState } = useResetPassword()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('AuthForms')
  const [oobCode, setOobCode] = React.useState<string>('')

  React.useEffect(() => {
    if (resetPasswordState.type === 'success') {
      navigate('/')
    }
  }, [resetPasswordState, navigate])

  React.useEffect(() => {
    const code = searchParams.get('oobCode') || ''
    setOobCode(code)
  }, [searchParams])

  const handleResetPassword = async (newPassword: string) => {
    try {
      await confirmResetPassword(oobCode, newPassword)
    } catch (error) {
      console.error(error)
    }
  }

  if (
    resetPasswordState.type === 'success' ||
    resetPasswordState.type === 'loading'
  ) {
    return <Loading />
  }

  return (
    <Container>
      <AuthForms.CreatePassword
        titleText={t('newPasswordTitle')}
        onSubmit={handleResetPassword}
        error={resetPasswordState.type === 'error'}
      />
    </Container>
  )
}

export default NewPassword
