/** Libs */
import React from 'react'
import { UpperContainer } from '../styles'

interface INavbarProps {
  children: React.ReactNode
}

const Upper: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  return <UpperContainer>{children}</UpperContainer>
}

export default Upper
