import UrlLink from './UrlLink'

/** Styles */
import { Tbody, TdLink, Th, Td, Thead, StyledTable } from './styles'

/** Interfaces */
import { IDomain, ITableItem } from '../../services/interfaces'
import { formatDomain } from '../../services/helper'

/** Tokens */
import { neutral } from '../../assets/tokens/colors'

interface ITableHeader {
  id: string
  text: () => string
  className?: string
}

interface ITable {
  data: ITableItem[]
  domain?: IDomain
  headers: ITableHeader[]
  url?: boolean
}

const Table: React.FC<ITable> = ({ data, domain, headers, url }) => {
  const formattedDomain = formatDomain(domain)

  if (!data) return <></>

  return (
    <StyledTable>
      <Thead>
        <tr>
          {headers.map((header) => (
            <Th
              key={header.id}
              id={header.id}
              scope="col"
              className={header.className}
            >
              {header.text()}
            </Th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {data.map((page, index) => (
          <tr
            key={page.source + index}
            style={
              index % 2 === 0
                ? { background: neutral.color.high.lightest }
                : { background: neutral.color.high.main }
            }
          >
            <Td headers={headers[1].id}>{index + 1}º</Td>
            {url ? (
              <TdLink headers={headers[2].id}>
                <UrlLink domain={formattedDomain} route={page.source} />
              </TdLink>
            ) : (
              <Td headers={headers[2].id}>{page.source}</Td>
            )}
            <Td headers={headers[3].id}>{page.value}</Td>
          </tr>
        ))}
      </Tbody>
    </StyledTable>
  )
}

export default Table
