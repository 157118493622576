import React from 'react'

/** Libs */
import { useTranslation } from 'react-i18next'
import { parse } from 'date-fns'

/** Services */
import { IPeriod } from '../../services/interfaces'

/** Types */
import { IFilterProps } from './types'

/** Components */
import { PeriodFilter } from './PeriodFilter'
import { IRadioOption } from '../Radio'

const Filter: React.FC<IFilterProps> = ({
  onFilter: onChange,
  initialData,
  options,
  periodReport,
  tagReport,
}) => {
  const { t: translateFilter } = useTranslation('Filter')
  const [prevFilterData, setPrevFilterData] = React.useState(initialData)
  const [filterData, setFilterData] = React.useState(initialData)
  const [invalidPeriodError, setInvalidPeriodError] = React.useState<string>()

  const setPeriod = React.useCallback((period: IPeriod) => {
    setFilterData((prev) => {
      return {
        ...prev,
        period,
      }
    })
  }, [])

  const filter = React.useCallback(() => {
    setPrevFilterData(filterData)
    if (onChange) onChange(filterData)
  }, [filterData, onChange])

  const availablePeriod =
    filterData.view === 'monthlyAverage'
      ? options?.averageAvailablePeriod
      : options?.defaultAvailablePeriod

  const isChanged = React.useMemo(() => {
    return (
      prevFilterData.period.startDate !== filterData.period.startDate ||
      prevFilterData.period.endDate !== filterData.period.endDate ||
      prevFilterData.view !== filterData.view
    )
  }, [prevFilterData, filterData])

  const hasAllFieldsFilled = React.useMemo(() => {
    const { period } = filterData
    return period.startDate && period.endDate
  }, [filterData])

  const invalidPeriodRangeError = React.useMemo(() => {
    if (!hasAllFieldsFilled || !availablePeriod) return false

    const { period } = filterData
    const today = new Date()

    const startDate = parse(period.startDate, 'yyyy-MM-dd', today)
    const endDate = parse(period.endDate, 'yyyy-MM-dd', today)

    const minDate = parse(availablePeriod.startDate, 'yyyy-MM-dd', today)
    const maxDate = parse(availablePeriod.endDate, 'yyyy-MM-dd', today)

    const hasError =
      startDate > endDate || startDate < minDate || endDate > maxDate

    if (hasError) return translateFilter('rangeError')
  }, [filterData, availablePeriod, hasAllFieldsFilled, translateFilter])

  const errorMessage = invalidPeriodError || invalidPeriodRangeError

  const handleOnInvalidPeriod = React.useCallback(() => {
    setInvalidPeriodError(translateFilter('invalidPeriod'))
  }, [translateFilter])

  const handleChangePeriod = React.useCallback(
    (period: IPeriod) => {
      setInvalidPeriodError('')
      setPeriod(period)
    },
    [setPeriod, setInvalidPeriodError]
  )

  const handleRadioChange = (data: IRadioOption) => {
    setFilterData((prev) => ({
      ...prev,
      view: data.value,
    }))
  }

  return (
    <PeriodFilter
      filterData={filterData}
      availablePeriod={availablePeriod!}
      onRadioChange={handleRadioChange}
      onChange={handleChangePeriod}
      onInvalidPeriod={handleOnInvalidPeriod}
      error={errorMessage}
      disabledFilter={!isChanged || !hasAllFieldsFilled || !!errorMessage}
      onChangeFilter={filter}
      periodReport={periodReport}
      tagReport={tagReport}
    />
  )
}

export default Filter
