/** Libs */
import React from 'react'
import { LowerContainer } from '../styles'

interface INavbarProps {
  children: React.ReactNode
}

const Lower: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  return <LowerContainer>{children}</LowerContainer>
}

export default Lower
