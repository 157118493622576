import React from 'react'

/** Libs */
import { YButton } from '@hand-talk/yotta-components'
import { format, parse, isValid } from 'date-fns'
import { useTranslation } from 'react-i18next'

/** Interfaces */
import { IInputDate } from './interfaces'

/** Services */
import { Timestamp } from '../../services/Timestamp'
import usePreferencies from '../../hooks/usePreferencies'

/** Components */
import useCalendarModal from '../YCalendar/DatepickerPopup/useCalendarModal'
import FilterButton from '../FilterButton'
import Radio, { IRadioOption } from '../Radio'
import { InputDate } from './InputDate'
import { DatepickerPopup } from '../YCalendar/DatepickerPopup'
import { YDatepickerRange } from '../YDatepickerRange'

/** Styles */
import { Buttons, ErrorMessage, Inputs, Container } from './styles'

export const YInputDate: React.FC<IInputDate> = ({
  disabled,
  value,
  onValueChange,
  onInvalidPeriod,
  error,
  max,
  min,
  view,
  onDisableDay,
  disabledFilter,
  onChangeFilter,
  onRadioChange,
  periodReport,
  tagReport,
}) => {
  /** Controla a exibição do calendário */
  const { shownCalendar, toggleCalendar, hideCalendar } = useCalendarModal()
  const { t: translateFilter } = useTranslation('Filter')
  const { oralLanguage } = usePreferencies()

  const [startValue, setStartValue] = React.useState('')
  const [endValue, setEndValue] = React.useState('')
  const [isValidPeriod, setIsValidPeriod] = React.useState(true)
  const [validationError, setValidationError] = React.useState<
    string | undefined
  >(undefined)

  const dataOption: IRadioOption[] = [
    {
      value: 'standard',
      label: translateFilter('standard'),
      title: translateFilter('titleStandard'),
    },
    {
      value: 'monthlyAverage',
      label: translateFilter('monthlyAverage'),
      title: translateFilter('titleMonthlyAverage'),
    },
  ]

  /** Define o estado inicial do componente de rádio */
  const [selectedOption, setSelectedOption] = React.useState(view)

  /** Verifica se a opção do componente rádio foi alterada */
  const isRadioChanged = selectedOption !== view

  const mask = oralLanguage === 'pt' ? '00/00/00' : '00-00-00'
  const dateFormat = translateFilter('dateFormat')
  const placeholder = translateFilter('placeholder')
  const startDateLabel = translateFilter('startDateLabel')
  const endDateLabel = translateFilter('endDateLabel')
  const regex =
    oralLanguage === 'pt' ? /\d{2}\/\d{2}\/\d{2}/ : /\d{2}-\d{2}-\d{2}/

  React.useEffect(() => {
    if (disabled) hideCalendar()
  }, [disabled, hideCalendar])

  const handleStartDateChange = (value: string) => {
    setStartValue(value)
    validatePeriod(value, endValue)
  }

  const handleEndDateChange = (value: string) => {
    setEndValue(value)
    validatePeriod(startValue, value)
  }

  const validatePeriod = (start: string, end: string) => {
    const startMatch = start.match(regex)
    const endMatch = end.match(regex)

    if (!startMatch || !endMatch) {
      setIsValidPeriod(false)
      setValidationError(translateFilter('invalidPeriod'))
      onInvalidPeriod?.()
      return
    }

    const startDate = parse(startMatch[0], dateFormat, new Date())
    const endDate = parse(endMatch[0], dateFormat, new Date())
    const isValidStartDate = isValid(startDate)
    const isValidEndDate = isValid(endDate)
    const startTimestamp = isValidStartDate
      ? (startDate.getTime() as Timestamp)
      : null
    const endTimestamp = isValidEndDate
      ? (endDate.getTime() as Timestamp)
      : null

    const periodIsValid =
      isValidStartDate && isValidEndDate && startDate <= endDate
    setIsValidPeriod(periodIsValid)
    setValidationError(
      periodIsValid ? undefined : translateFilter('rangeError')
    )

    if (periodIsValid && startTimestamp && endTimestamp) {
      onValueChange([startTimestamp, endTimestamp])
    } else {
      onInvalidPeriod?.()
    }
  }

  React.useEffect(() => {
    if (value) {
      const [startTimestamp, endTimestamp] = value
      const startDate = new Date(startTimestamp)
      const endDate = new Date(endTimestamp)
      setStartValue(format(startDate, dateFormat))
      setEndValue(format(endDate, dateFormat))
    }
  }, [value, dateFormat])

  const normalizedStartValue = React.useMemo(() => {
    return isValid(parse(startValue, dateFormat, new Date()))
      ? format(parse(startValue, dateFormat, new Date()), dateFormat)
      : startValue
  }, [startValue, dateFormat])

  const normalizedEndValue = React.useMemo(() => {
    return isValid(parse(endValue, dateFormat, new Date()))
      ? format(parse(endValue, dateFormat, new Date()), dateFormat)
      : endValue
  }, [endValue, dateFormat])

  /** Lida com a mudança de opção do componente rádio */
  const handleRadioChange = (option: {
    value: 'standard' | 'monthlyAverage'
  }) => {
    setSelectedOption(option.value)
    onRadioChange({
      value: option.value,
      label: '',
      title: '',
    })
  }

  const applyFilter = () => {
    onChangeFilter()
  }

  const onSelectPeriod = (value: [Timestamp, Timestamp]) => {
    setValidationError(undefined)
    onValueChange(value)
  }

  return (
    <>
      <FilterButton
        tagReport={tagReport}
        periodReport={periodReport}
        period={`${normalizedStartValue} - ${normalizedEndValue}`}
        onFilter={toggleCalendar}
        showCalendar={shownCalendar}
      />
      <Container>
        <DatepickerPopup
          shownCalendar={shownCalendar}
          hide={toggleCalendar}
          headerText="calendar"
        >
          <Radio
            options={dataOption}
            selectedOption={
              dataOption.find((option) => option.value === selectedOption) ||
              dataOption[0]
            }
            name="filterRadioGroup"
            onChange={handleRadioChange}
          />
          <Inputs>
            <InputDate
              title={translateFilter('titleInputStartDate')}
              ariaLabel={translateFilter('titleInputStartDate')}
              label={startDateLabel}
              mask={mask}
              placeholder={placeholder}
              onValueChange={handleStartDateChange}
              value={normalizedStartValue}
              disabled={disabled}
              max={max}
              min={min}
              error={isValidPeriod ? undefined : validationError}
            />
            <InputDate
              title={translateFilter('titleInputEndDate')}
              ariaLabel={translateFilter('titleInputEndDate')}
              label={endDateLabel}
              mask={mask}
              placeholder={placeholder}
              onValueChange={handleEndDateChange}
              value={normalizedEndValue}
              disabled={disabled}
              max={max}
              min={min}
              error={isValidPeriod ? undefined : validationError}
            />
          </Inputs>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <YDatepickerRange
            isAverageReport={selectedOption === 'monthlyAverage'}
            selected={value}
            onSelect={onSelectPeriod}
            onDisableDay={onDisableDay}
          />
          <Buttons>
            <YButton
              variation="text"
              onClick={hideCalendar}
              size="fitContent"
              aria-label={translateFilter('cancel')}
              children={translateFilter('cancel')}
              title={translateFilter('cancel')}
            />
            <YButton
              variation="fill"
              disabled={!isRadioChanged && disabledFilter}
              onClick={applyFilter}
              size="fitContent"
              aria-label={translateFilter('filterLabel')}
              children={translateFilter('filter')}
              title={translateFilter('filter')}
            />
          </Buttons>
        </DatepickerPopup>
      </Container>
    </>
  )
}
