// Libs
import { useTranslation } from 'react-i18next'

// Services
import { ITableItem } from '../../services/interfaces'

// Components
import CustomContainer from '../CustomContainer'
import Title from '../Title'
import TopStates from './TopStates'
import TopDevices from './TopDevices'
import TopPages from './TopPages'

// Styles
import { Container } from './styles'

export interface IUserChart {
  topStates: ITableItem[]
  topDevices: ITableItem[]
  topPages: ITableItem[]
  period: string
  domain: string
  isAverage: boolean
}

const UserTable: React.FC<IUserChart> = ({
  topStates,
  topDevices,
  topPages,
  period,
  domain,
  isAverage,
}) => {
  const { t: translationUserTable } = useTranslation('UserTable')

  return (
    <CustomContainer className="PrintOnly">
      {!isAverage && (
        <Title
          tag={'h2'}
          className={'TitleAll'}
          children={translationUserTable('titleAnalysis')}
        />
      )}
      <Container>
        <TopStates isAverage={isAverage} topStates={topStates} />
        <TopDevices isAverage={isAverage} topDevices={topDevices} />
      </Container>
      <TopPages
        topPages={topPages}
        period={period}
        domain={{
          name: domain,
          value: `https://${domain}/`,
        }}
        isAverage={isAverage}
      />
    </CustomContainer>
  )
}

export default UserTable
