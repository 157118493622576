// Libs
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

// Hooks
import useFirstAccessViewModel from '../../hooks/view-models/useFirstAccessViewModel'

// Components
import AuthForms from '../../components/AuthForms'
import Container from '../../components/Container'
import Loading from '../../components/Loading'

export default function FirstAccess() {
  const { state, createPassword, decodeToken } = useFirstAccessViewModel()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('AuthForms')

  const token = useMemo(() => searchParams.get('token') || '', [searchParams])

  useEffect(() => {
    decodeToken(token)
  }, [token, decodeToken])

  useEffect(() => {
    if (state.type === 'success') {
      window.location.href = '/domain-list'
    }
  }, [state])

  if (state.type === 'loading') {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if (state.type === 'token-error') {
    return (
      <Container>
        <AuthForms.Expired />
      </Container>
    )
  }

  return (
    <Container>
      <AuthForms.CreatePassword
        titleText={t('firstAccessTitle')}
        onSubmit={createPassword}
        error={state.type === 'password-error'}
      />
    </Container>
  )
}
