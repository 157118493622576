import React from 'react'
import i18n from '../i18n'

/** Services */
import { Language } from '../services/interfaces'
import { getOralLanguage } from '../services/utils'
import { getStoredItem, setStoredItem } from '../services/localStorage'

export interface IPreferencies {
  noAnalytics: boolean
  accessKey: string
  language: Language
  oralLanguage: string
}

export const usePreferencies = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const rawLanguage = urlParams.get('language') as Language

  /**
   * Define o atributo lang do documento
   */
  const initializeLanguage = async (lang: Language) => {
    const oralLanguage = getOralLanguage(lang)
    await i18n.changeLanguage(oralLanguage)
    document.documentElement.lang = i18n.language
  }

  const getInitialLanguage = (): Language => {
    /**
     * Verifica se o idioma do usuário já foi armazenado
     */
    const storedLanguage = getStoredItem<Language>('language')
    if (storedLanguage) {
      return storedLanguage
    }
    /**
     * Verifica se a URL contém a língua,
     * caso contrário, retorna o idioma padrão 'ptBR-bzs'
     */
    return Object.values(Language).includes(rawLanguage)
      ? rawLanguage
      : Language['ptBR-bzs']
  }

  const [language, setLanguage] = React.useState<Language>(getInitialLanguage)

  React.useEffect(() => {
    const storedLanguage = getStoredItem<Language>('language')
    /**
     * Observa o idioma, e se for diferente do armazenado, atualiza.
     */
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage)
    } else {
      initializeLanguage(language)
    }
  }, [language])

  const updateLanguage = async (newLanguage: Language) => {
    await initializeLanguage(newLanguage)
    setLanguage(newLanguage)
    /**
     * Salva o idioma no armazenamento local para manter a preferência do usuário
     */
    setStoredItem('language', newLanguage)
  }

  return {
    noAnalytics: urlParams.get('no_analytics') === 'true',
    accessKey: window.location.pathname.substring(1),
    language,
    oralLanguage: getOralLanguage(language),
    setLanguage: updateLanguage,
  }
}

export default usePreferencies
