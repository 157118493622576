/** Libs */
import styled from 'styled-components'

/** Tokens */
import { font } from '../../assets/tokens/fonts'
import { brand, highlight, neutral } from '../../assets/tokens/colors'

/** Services */
import { tabletMediaQuery } from '../../services/helper'

export const StyleLine = styled.div<{ double?: boolean }>`
  width: 24px;

  .gray {
    background-color: #bdbdbd;
    display: inline-block;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  .line {
    width: 6px;
    height: 180px;
  }

  @media (max-width: 1239px) {
    > div:nth-child(1) {
      display: ${(props) => (props.double ? 'none' : 'initial')};
    }

    > div:nth-child(2),
    div:nth-child(3) {
      display: none;
    }
  }
`

export const Section = styled.section`
  padding-bottom: 42px;

  .TitleTimeline {
    padding: 58px 0;
    text-align: center;
    font: ${font.heading.large};
    font-family: ${font.family};
    color: ${brand.color.new};
  }

  ${tabletMediaQuery(`
    .LineMobile {
      background-color:  #bdbdbd;
      display: inline-block;
      width: 6px;
      height: 180px;
    }
  `)}
`

export const StyleCard = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.direction === 'left' ? 'row-reverse' : 'undefined'};

  ${tabletMediaQuery(`
    flex-direction: column;
  `)}
`

export const Month = styled.div<{ double?: boolean }>`
  width: 170px;
  height: auto;
  padding: 12px;
  background-color: ${brand.color.new};
  text-align: center;
  border-radius: 16px;
  color: ${neutral.color.high.lightest};
  font: ${font.subtitle.medium};
  font-family: ${font.family};
  display: ${(props) => (props.double ? 'none' : 'initial')};
`

export const Row = styled.div`
  width: 170px;
`

export const Space = styled.div`
  width: 356px;

  ${tabletMediaQuery(`
    display: none;
  `)}
`

export const Events = styled.div<{ noUrl?: boolean }>`
  width: 356px;
  height: auto;
  padding: 28px;
  border-radius: 16px;
  border: solid 2px ${brand.color.new};
  margin-top: 60px;

  button {
    display: ${(props) => (props.noUrl ? 'none' : 'initial')};
    margin-top: 16px;
  }

  ${tabletMediaQuery(`
     max-width: 356px;
     width: 100%;
     margin-top: 0;
  `)}

  @media print {
    margin-top: 5px;

    button {
      display: none;
    }
  }
`

export const SubTitle = styled.p`
  font: ${font.heading.large};
  font-family: ${font.family};
  color: ${highlight.color.new};
`

export const Text = styled.p`
  font: ${font.subtitle.small};
  font-family: ${font.family};
  color: ${brand.color.new};
`
