//libs
import { inject, injectable } from 'inversify'

// Apis
import DashboardApi from '../apis/DashboardApi'

// Types
import { IPeriod, IAverageReportSchema, Language } from '../services/interfaces'

@injectable()
export default class AverageReportRepository {
  @inject(DashboardApi) dashboardApi: DashboardApi

  public filter = async (
    accessKey: string,
    period: IPeriod,
    language: Language
  ) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.')

    const report = await this.dashboardApi.get<IAverageReportSchema>(
      'filterAverageReport',
      {
        ...period,
        accessKey,
        language,
      }
    )

    return report
  }
}
