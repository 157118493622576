/** Libs */
import React from 'react'
import Select, { StylesConfig, components } from 'react-select'

/** Styles */
import { IconWrapper } from './styles'

/** Icons */
import BrCircleFlag from '../Icons/BrCircleFlag'
import UsCircleFlag from '../Icons/UsCircleFlag'

import { isMobile } from '../../services/helper'

interface ISelect {
  value: string
  onChange: (value: string) => void
  options: string[]
}

interface ILanguageMapping {
  [key: string]: { value: string; label: string; icon: () => JSX.Element }
}

const languageMapping: ILanguageMapping = {
  'ptBR-bzs': { value: 'ptBR-bzs', label: 'Libras', icon: BrCircleFlag },
  'en-ase': { value: 'en-ase', label: 'ASL', icon: UsCircleFlag },
}

const CustomSelect: React.FC<ISelect> = ({ value, onChange, options }) => {
  const preparedOptions = options.map((option) => ({
    value: option,
    label: languageMapping[option]?.label || option,
    icon: languageMapping[option]?.icon,
  }))

  const { Option, SingleValue } = components

  const customStyles: StylesConfig<any, false> = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'pointer',
      borderRadius: 12,
      border: '1px solid #818181',
      padding: '10px 16px',
      color: '#333333',
      fontFamily: 'Lato", sans-serif',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    container: (provided) => ({
      ...provided,
      marginRight: !isMobile() ? 18 : 0,
      marginTop: isMobile() ? 12 : 0,
      marginBottom: isMobile() ? 32 : 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 0 0 8px',
    }),
  }

  const IconOption = (props: any) => (
    <Option {...props}>
      {props.data.icon && (
        <IconWrapper>
          <props.data.icon />
        </IconWrapper>
      )}
      {props.data.label}
    </Option>
  )

  const CustomSingleValue = ({ children, ...props }: any) => (
    <SingleValue {...props}>
      {props.data.icon && (
        <IconWrapper>
          <props.data.icon />
        </IconWrapper>
      )}
      {children}
    </SingleValue>
  )

  const handleSelectChange = (selectedOption: any) => {
    onChange(selectedOption ? selectedOption.value : '')
  }

  const selectedValue = preparedOptions.find((option) => option.value === value)

  return (
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      options={preparedOptions}
      components={{ Option: IconOption, SingleValue: CustomSingleValue }}
      styles={customStyles}
    />
  )
}

export default CustomSelect
