// Core
import TYPES from '../../core/Types'

// Domain
import CreateUserPasswordUseCase from '../../domain/use-cases/CreateUserPasswordUseCase'

// Main
import { container } from '../../inversify.config'

export default function useCreateUserPasswordUseCase() {
  return container.get(
    TYPES.CreateUserPasswordUseCase
  ) as CreateUserPasswordUseCase
}
