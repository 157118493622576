/** Libs */
import styled from 'styled-components'

/** Services */
import { getIconStyle } from '../../services/helper'

/** Tokens */
import { font } from '../../assets/tokens/fonts'
import { neutral } from '../../assets/tokens/colors'
import { spacing } from '../../assets/tokens/spacings'

export const ContentAchievement = styled.div`
  width: 100%;
`

export const Text = styled.p`
  margin: 40px 0 0 0;
  font: ${({ theme }) => theme.typography.label.sm};
  color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
`

export const Value = styled.p`
  font: ${({ theme }) => theme.typography.title.sm};
  color: ${({ theme }) => theme.colors.secondary.default[0]};
`

export const StyledText = styled.p`
  font: ${font.subtitle.X2s};
  font-family: ${font.family};
  color: ${neutral.color.low.medium};
`

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${spacing.nano};
`

export const ImageStyled = styled.span`
  ${getIconStyle('52px')};

  > svg {
    @media (max-width: 599px) {
      width: 48px;
      height: 48px;
    }
  }
`

/** Bar */

export const BarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledBar = styled.div`
  width: 100%;
  height: ${spacing.nano};
`

export const Marked = styled.div`
  width: 2px;
  height: 5px;
`

/** Bar */
