/** Libs */
import styled from 'styled-components'

/** Tokens */
import { font } from '../../assets/tokens/fonts'
import { brand, neutral } from '../../assets/tokens/colors'

/** Services */
import { printMediaQuery, tabletMediaQuery } from '../../services/helper'

interface ICard {
  color: string
  padding: string
}

export const Section = styled.section`
  margin-bottom: 58px;

  .CardPages {
    margin-left: 16px;
  }

  .CardTranslations {
    margin-bottom: 16px;
  }

  ${tabletMediaQuery(`
    .CardPages {
      margin: 16px 0 0;
      flex-direction: column-reverse;
    }
  `)}

  ${printMediaQuery(`
    .CardPages {
      padding-bottom: 24px;
     }
  `)}
`

export const ContentTitle = styled.div`
  padding: 42px 0 58px;

  .TitleResume {
    font: ${font.heading.large};
    color: ${brand.color.new};
    font-family: ${font.family};
    margin-bottom: 12px;
  }

  ${printMediaQuery(`
    padding: 0 0 26px;
  `)}
`

export const Image = styled.img<{ below?: boolean }>`
  padding-right: 24px;
  object-fit: cover;

  @media (max-width: 1239px) {
    padding: ${({ below }: { below?: boolean }) =>
      below ? '24px 0 0' : '0 0 24px'};
  }

  ${printMediaQuery(`
    display: none;
  `)}
`

export const Card = styled.div<ICard>`
  background: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  border-radius: 16px;
  display: flex;
  align-items: center;

  ${tabletMediaQuery(`
    flex-direction: column;
    width: 100%;
  `)}

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: ${({ color }) => color} !important;
    width: auto;
  }
`

export const List = styled.li`
  padding-top: 24px;
  display: flex;
  align-items: center;

  a {
    font: ${font.subtitle.Xsmall};
    font-family: ${font.family};
    text-align: center;
    color: ${neutral.color.high.lightest};
    word-break: break-all;
    margin-left: 16px;
  }
`
