import React from 'react'

/** Components */
import CustomContainer from '../CustomContainer'
import CustomText from '../CustomText'

/** Styles */
import { Column, Container } from './styles'

/** Tokens */
import { brand, highlight } from '../../assets/tokens/colors'

const TopContainerRetrospective: React.FC = () => {
  return (
    <CustomContainer>
      <Container>
        <Column className="FlexColumn" width={213}>
          <CustomText
            as="h1"
            size={30}
            color={highlight.color.new}
            height={27}
            weight={900}
          >
            Retrospectiva
          </CustomText>
          <CustomText
            size={80}
            color={brand.color.new}
            height={72}
            weight={900}
          >
            2023
          </CustomText>
        </Column>
        <div className="divider"></div>
        <Column className="FlexColumn" width={244}>
          <CustomText
            size={14}
            color={highlight.color.new}
            height={24}
            weight={700}
          >
            Vamos relembrar os principais marcos e conquistas do
          </CustomText>
          <CustomText
            size={32}
            color={brand.color.new}
            height={41.6}
            weight={900}
          >
            Hand Talk Plugin
          </CustomText>
        </Column>
      </Container>
    </CustomContainer>
  )
}

export default TopContainerRetrospective
