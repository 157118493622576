/** Libs */
import React from 'react'
import { useTranslation } from 'react-i18next'

/** Interfaces */
import { ICalendarDay } from './interfaces'

/** Services */
import { lastDateOfMonth } from '../../../services/utils'

/** Styles */
import { WrapperCalendarDay, ButtonCalendar } from './styles'

const CalendarDay: React.FC<React.PropsWithChildren<ICalendarDay>> = (
  props
) => {
  const {
    children,
    isCurrentMonth = true,
    isSelected = false,
    dayTimestamp,
    highlightedMode,
    onClick,
    onPointerEnter,
    onPointerLeave,
    onDisable,
    isAverageReport,
  } = props

  const { t: translateYCalendar } = useTranslation('YCalendar')

  const now = new Date()
  const date = new Date(dayTimestamp)
  const isToday = date.toDateString() === now.toDateString()

  const handleSelectMonth = (month: number) => {
    const monthNames = [
      translateYCalendar('Months.January'),
      translateYCalendar('Months.February'),
      translateYCalendar('Months.March'),
      translateYCalendar('Months.April'),
      translateYCalendar('Months.May'),
      translateYCalendar('Months.June'),
      translateYCalendar('Months.July'),
      translateYCalendar('Months.August'),
      translateYCalendar('Months.September'),
      translateYCalendar('Months.October'),
      translateYCalendar('Months.November'),
      translateYCalendar('Months.December'),
    ]

    return monthNames[month - 1]
  }

  const day = date.getDate()
  /** Obtém o nome do mês */
  const month = handleSelectMonth(date.getMonth() + 1)
  const year = date.getFullYear()

  const oneYearBefore = new Date()
  /** Data para um ano antes da data atual */
  oneYearBefore.setFullYear(now.getFullYear() - 1)

  const handleClick = React.useCallback(() => {
    onClick(dayTimestamp)
  }, [onClick, dayTimestamp])

  const handlePointerEnter = React.useCallback(() => {
    if (onPointerEnter) {
      onPointerEnter(dayTimestamp)
    }
  }, [onPointerEnter, dayTimestamp])

  const handlePointerLeave = React.useCallback(() => {
    if (onPointerLeave) {
      onPointerLeave(dayTimestamp)
    }
  }, [onPointerLeave, dayTimestamp])

  const isDisabled = onDisable && onDisable(dayTimestamp)

  let ariaLabel = translateYCalendar('ariaLabel', { day, month, year })

  if (isDisabled) {
    /**
     * Se a data estiver bloqueada e for de 1 ano atrás usando a métrica padrão,
     * Utiliza um título e texto alternativos diferentes
     */
    if (!isAverageReport && date < oneYearBefore) {
      ariaLabel = translateYCalendar('blockedBeforeYearAriaLabel', {
        day,
        month,
        year,
      })
    } else {
      ariaLabel = translateYCalendar('blockedDateAriaLabel', {
        day,
        month,
        year,
      })
    }
  }

  return (
    <WrapperCalendarDay
      data-day={date.getDay()}
      data-first-date={date.getDate() === 1}
      data-last-date={lastDateOfMonth(dayTimestamp) === date.getDate()}
      data-highlighted-mode={highlightedMode}
    >
      <ButtonCalendar
        type="button"
        disabled={isDisabled}
        data-today={isToday}
        data-selected={isSelected}
        data-current-month={isCurrentMonth}
        data-highlighted-mode={highlightedMode}
        aria-label={ariaLabel}
        title={ariaLabel}
        onClick={handleClick}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        {children}
      </ButtonCalendar>
    </WrapperCalendarDay>
  )
}

const CalendarDayMemo = React.memo(CalendarDay)
export { CalendarDayMemo as CalendarDay }
