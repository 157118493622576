// Libs
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Components
import Container from '../../components/Container'
import Loading from '../../components/Loading'
import useOobCodeValidation from '../../hooks/useOobCodeValidation'
import AuthForms from '../../components/AuthForms'

const routes: Record<string, string> = {
  signIn: '/first-access',
  resetPassword: '/new-password',
}

export default function AuthHandle() {
  const { validate, oobCodeValidationState } = useOobCodeValidation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const oobCode = searchParams.get('oobCode')
    validate(oobCode)
  }, [validate, searchParams])

  useEffect(() => {
    if (oobCodeValidationState.type === 'success') {
      const mode = searchParams.get('mode')
      const route = mode ? routes[mode] : '/'
      navigate(`${route}?${searchParams.toString()}`)
    }
  }, [oobCodeValidationState, searchParams, navigate])

  if (oobCodeValidationState.type === 'error') {
    return (
      <Container>
        <AuthForms.Expired />
      </Container>
    )
  }

  return (
    <Container>
      <Loading />
    </Container>
  )
}
