/** Libs */
import { useTranslation } from 'react-i18next'

/** Styles */
import { ContainerAll, Info, StyledLink, WrapperInvalidToken } from './styles'

/** Services */
import { emailCS } from '../../services/constants'

/** Components */
import CustomContainer from '../CustomContainer'
import Title from '../Title'

const InvalidToken: React.FC = () => {
  const { t: translateInvalidToken } = useTranslation('InvalidToken')

  return (
    <CustomContainer>
      <WrapperInvalidToken>
        <ContainerAll>
          <Info>
            <Title
              tag={'h1'}
              className={'TitleInvalidToken'}
              children={translateInvalidToken('invalidTokenTitle')}
            />

            <p>
              {translateInvalidToken('invalidTokenMessage')}
              <StyledLink href={emailCS} rel="noreferrer" target="_blank">
                cs@handtalk.me
              </StyledLink>
            </p>
          </Info>
        </ContainerAll>
        <div className="RainbowLoader RainbowLoaderBorder"></div>
      </WrapperInvalidToken>
    </CustomContainer>
  )
}

export default InvalidToken
