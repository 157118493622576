/** Libs */
import styled from 'styled-components'

/** Services */
import { mobileMediaQuery } from '../../services/helper'

/** Tokens */
import { font } from '../../assets/tokens/fonts'
import { highlight } from '../../assets/tokens/colors'

export const LoadingStyled = styled.div`
  padding-top: 247px;
  padding-bottom: 247px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobileMediaQuery(`
    padding-top: 127px;
    padding-bottom: 127px;
  `)}
`

export const Text = styled.p`
  font: ${font.subtitle.small};
  font-family: ${font.family};
  color: ${highlight.color.main};
  padding: 5px 0;
  margin-left: 24px;
`
