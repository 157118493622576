// Components
import Header from '../../components/Header'
import InvalidToken from '../../components/InvalidToken'
import Footer from '../../components/Footer'

const NotFound = () => (
  <>
    <Header.Navbar />
    <InvalidToken />
    <Footer />
  </>
)

export default NotFound
