// Libs
import { css } from 'styled-components'
import { format, parse, parseISO } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'

// Interfaces
import { IDomain, IPeriod } from './interfaces'
import { blogBR, blogEN } from './constants'

export const periodDay = (period: string) => {
  return period.slice(6)
}

/** Retorna a mudança de cor e tamanho de um svg */
const iconStyle = (size: string, color?: string) => css`
  > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${size};
    height: ${size};
  }

  // Mudança de cor
  ${color &&
  css`
    > svg path,
    > svg circle,
    > svg rect {
      fill: ${color};
    }
    > svg line {
      fill: ${color};
      stroke: ${color};
    }
  `}
`

/** Retorna o conjunto de estilos para o estilo */
export const getIconStyle = (
  size: string,
  color?: string,
  hoverColor?: string
) => css`
  ${iconStyle(size, color)}
  ${hoverColor &&
  css`
    &:hover {
      ${iconStyle(size, hoverColor)}
    }
  `}
`

export const formatDomain = (domainInput: IDomain | string | undefined) => {
  let content

  // Verifica o tipo de entrada e extrai o valor de domínio apropriado
  if (typeof domainInput === 'object') {
    if (!domainInput) {
      return '#'
    }
    content = domainInput.value
  } else {
    content = domainInput
  }

  // Restante da lógica para formatação do domínio
  if (!content || content === '') {
    return '#'
  }

  content = content
    .replace('www.', '')
    .replace('https://', '')
    .replace('http://', '')

  const pos = content.length - 1
  if (content[pos] === '/') {
    content = content.substring(0, pos)
  }

  return 'https://' + content + '/'
}

/**
 * Capitaliza a primeira letra de uma string.
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Abrevia o mês formatado em letras maiúsculas
 * @param period Período a ser verificado em string
 * @param language Especifica o idioma da formatação da data. Exemplo: `en` ou `pt`.
 */
export const abbreviateMonth = (period: string, language: string) => {
  const date = parseISO(period)
  const formatString = 'MMM/yy'
  const locale = language === 'en' ? enUS : ptBR
  const formattedDate = format(date, formatString, { locale })

  const [month, year] = formattedDate.split('/')
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)
  return `${capitalizedMonth}/${year}`
}

/** Função que retorna a media query pra desktop
 * @param content String com o css que será aplicado
 */
export const desktopMediaQuery = (content: string) => {
  return `@media (max-width: 1440px) {
    ${content}
  }`
}

/** Função que retorna a media query pra tablet
 * @param content String com o css que será aplicado
 */
export const tabletMediaQuery = (content: string) => {
  return `@media (max-width: 1239px) {
    ${content}
  }`
}

/** Função que retorna a media query pra mobile
 * @param content String com o css que será aplicado
 */
export const mobileMediaQuery = (content: string) => {
  return `@media (max-width: 599px) {
    ${content}
  }`
}

/**
 * Função que retorna uma media query para impressão em todos os navegadores.
 * @param content String com o css que será aplicado na media query.
 */
export const printMediaQuery = (content: string) => {
  return `@media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    ${content}
  }`
}

/**
 * Função que retorna true se o dispositivo for mobile
 */
export const isMobile = () => {
  return window.matchMedia('(max-width: 599px)').matches
}

/**
 * Função que formata as datas para int
 * @param date String que representa uma data
 */
export const formatDateToInt = (date: string) => {
  // Transforma ano em int
  const year = parseInt(date.slice(0, 4))

  // Transforma mês em int
  let month
  if (date[4] === '0') {
    month = parseInt(date[5])
  } else month = parseInt(date.slice(4, 6))

  let day
  if (date.slice(6) !== '') day = date.slice(6)
  else day = 1

  return [year, month - 1, day]
}

/**
 * Formata as strings para um objeto Date
 * @param period String de uma data. Ex: "20010101"
 */
export const formatPeriodToDate = (period: string) => {
  const [year, month, day] = formatDateToInt(period)

  return new Date(year as number, month as number, day as number)
}

/**
 * Formata numeral com posfixo K, M ou B
 * @param value String ou número a ser formatado
 */
export const numberFormatter = (value: any) => {
  const intValue = parseInt(value)

  if (intValue >= 1000000000) {
    return (intValue / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (intValue >= 1000000) {
    return (intValue / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (intValue >= 1000) {
    return (intValue / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return intValue
}

/**
 * Formata os números para o padrão brasileiro
 * @param value String ou número a ser formatado
 */
export const ptBrNumberFormatter = (value: any, posfix?: boolean) => {
  const intValue = parseInt(value)

  if (intValue >= 10000 && posfix) {
    return numberFormatter(intValue)
  }

  const formatted = intValue.toLocaleString()
  return formatted.replace(/,/gi, '.')
}

/**
 * Formata numeral com posfixo K, M ou B
 * @param value
 */
export const formatDashboardValue = (value: number) => {
  if (value >= 1000000000) {
    return {
      formatted: (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G',
      pure: value,
    }
  }
  if (value >= 1000000) {
    return {
      formatted: (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M',
      pure: value,
    }
  }
  if (value >= 1000) {
    return {
      formatted: (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K',
      pure: value,
    }
  }
  return {
    formatted: `${value}`,
    pure: `${value}`,
  }
}

/**
 * Agrupa as traduções por mês, exemplo:
 * ```
 * {
 * "202208": {
 *   "01": <numero_traducoes>,
 *   "02": <numero_traducoes>,
 *    ...
 *   "30": <numero_traducoes>,
 * }
 * ```
 * @param dailyInfo Historico de traduções diárias
 */
export const getMonthlyInfo = (dailyInfo: Record<string, number>) => {
  return Object.entries(dailyInfo).reduce<
    Record<string, Record<string, number>>
  >((acc, [key, value]) => {
    const yearMonth = key.slice(0, 6)
    const dayOfMonth = key.slice(6)

    if (!acc[yearMonth]) {
      acc[yearMonth] = {}
    }

    acc[yearMonth][dayOfMonth] = value

    return acc
  }, {})
}

/**
 * Formata uma data LLL/yyyy
 * @param date
 * @returns
 */
export const formatDate = (date: string) => {
  const today = new Date()
  const formatedDate = format(parse(date, 'yyyy-MM-dd', today), 'LLL yyyy', {
    locale: ptBR,
  })
  // Primeira letra maiúscula
  return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1)
}

/**
 * Formata um período para o formato "LLL/yyyy",
 * Caso a data inicial e final sejam iguais, retorna apenas a data inicial
 * @param period periodo a ser formatado
 * @returns
 */
export const formatPeriod = (period: IPeriod) => {
  const start = formatDate(period.startDate)
  const end = formatDate(period.endDate)

  return start === end ? start : `${start} até ${end}`
}

/**
 * Formata uma data com base no idioma fornecido
 */
export const formatDateWithLocale = (
  date: string,
  language: string
): string => {
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date())

  const formatOptions = language === 'en' ? { locale: enUS } : { locale: ptBR }
  const formattedDate = format(
    parsedDate,
    language === 'en' ? 'MMMM do, yyyy' : 'dd MMMM yyyy',
    formatOptions
  )
  const [day, month, year] = formattedDate.split(' ')

  return `${day} ${capitalize(month)} ${year}`
}

/**
 * Formata um período de datas com base no idioma fornecido
 */
export const formatDateRange = (period: IPeriod, language: string) => {
  const start = formatDateWithLocale(period.startDate, language)
  const end = formatDateWithLocale(period.endDate, language)

  const separator = language === 'en' ? 'to' : 'até'

  return start === end ? start : `${start} ${separator} ${end}`
}

/**
 * Formata uma data com um modelo personalizado
 * @param date A data a ser formatada.
 * @param template O modelo a ser usado na formatação.
 */
export const formatDateWithCustomTemplate = (date: Date, template: string) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDate = template
    .replace('{{day}}', day.toString().padStart(2, '0'))
    .replace('{{month}}', month.toString().padStart(2, '0'))
    .replace('{{year}}', year.toString().substring(2))

  return formattedDate
}

/**
 * Retorna o template para o idioma especificado.
 * @param language O idioma a ser usado.
 */
export const getTemplate = (language: string) => {
  switch (language) {
    case 'br':
      return `{{day}}/{{month}}/{{year}}`
    case 'en':
      return `{{year}}-{{month}}-{{day}}`
    default:
      return `{{day}}/{{month}}/{{year}}`
  }
}

/**
 * Retorna o URL do blog com base na língua fornecida.
 * @param language O idioma a ser usado.
 */
export const getBlogURL = (language: string) => {
  switch (language) {
    case 'br':
      return blogBR
    case 'en':
      return blogEN
    default:
      return blogBR
  }
}

export const printPDF = (): void => {
  const win: Window | null = window
  if (!win) return
  win.focus()
  win.print()
}

export const formatPeriodSimple = (period: IPeriod): string => {
  return `${period.startDate}-${period.endDate}`
}

export const languageMapping: { [key: string]: string } = {
  'ptBR-bzs': 'Libras',
  'en-ase': 'ASL',
}

/**
 * Extrai dados de um array de objetos e retorna um array de objetos com propriedades de chave e valor especificadas.
 */
export const extractData = (
  data: Array<{ [key: string]: any }>,
  keyProperty: string,
  valueProperty: string
): { source: string; value: number }[] => {
  return data.map((item) => ({
    source: item[keyProperty],
    value: item[valueProperty],
  }))
}

/**
 * Transforma o objeto monthlyUsersInfo em um array de objetos adequado para o gráfico.
 * Cada objeto no array resultante contém a chave 'forMonth' e 'users', representando
 * o mês e a quantidade de usuários, respectivamente.
 *
 * @param {Object} monthlyUsersInfo - Objeto contendo as informações de usuários mensais,
 *                                    onde a chave é o mês e o valor é a quantidade de usuários.
 * @returns {Array} Array de objetos, cada um contendo 'forMonth' (mês) e 'users' (quantidade de usuários).
 */
export const transformMonthlyUsersData = (monthlyUsersInfo: {
  [key: string]: number
}) => {
  return Object.keys(monthlyUsersInfo).map((key) => ({
    forMonth: key,
    users: monthlyUsersInfo[key],
  }))
}

export const formatPeriodWithTemplate = (period: IPeriod, language: string) => {
  const template = getTemplate(language)

  const startDate = parseISO(period.startDate)
  const endDate = parseISO(period.endDate)

  const formattedStartDate = formatDateWithCustomTemplate(startDate, template)
  const formattedEndDate = formatDateWithCustomTemplate(endDate, template)

  return formattedStartDate === formattedEndDate
    ? formattedStartDate
    : `${formattedStartDate} - ${formattedEndDate}`
}
