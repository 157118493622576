import React from 'react'

/** Libs */
import { IMaskMixin, IMaskInputProps } from 'react-imask'

/** Styles */
import { ContainerStyle, IStyledInputProps, Label, StyledInput } from './styles'

/** Interfaces */
import { InputDateComponentProps } from './interfaces'

type CustomProps = IStyledInputProps & IMaskInputProps<HTMLInputElement>

const MaskedStyledInput = IMaskMixin<HTMLInputElement, CustomProps>(
  ({ inputRef, ...props }) => (
    // @ts-ignore
    <StyledInput {...props} ref={inputRef} />
  )
)

export const InputDate: React.FC<InputDateComponentProps> = ({
  mask,
  value,
  placeholder,
  onValueChange,
  disabled,
  error,
  label,
  title,
  ariaLabel,
}) => {
  const [focus, setFocus] = React.useState(false)

  return (
    <div>
      <Label>{label}</Label>
      <ContainerStyle
        data-disabled={disabled}
        data-focus-visible={focus}
        data-error={!!error}
      >
        <MaskedStyledInput
          mask={mask}
          placeholder={placeholder}
          disabled={disabled}
          required
          value={value}
          onComplete={(value, maskRef, e) => e && onValueChange(value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          error={!!error}
          title={title}
          aria-label={ariaLabel}
        />
      </ContainerStyle>
    </div>
  )
}
