/** Libs */
import { YHelpButton, YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Styles */
import { ContentAll, StyledMetric, DataCard } from './styles'

/** Components */
import Title from '../Title'
import CustomContainer from '../CustomContainer'
import Card from './baseCardOverview'

/** Services */
import { IOverview } from '../../services/interfaces'
import { isMobile } from '../../services/helper'

const Overview: React.FC<IOverview> = ({ period, data, isAverageReport }) => {
  const { t: translateOverview } = useTranslation('Overview')
  return (
    <CustomContainer>
      <Title
        tag={'h2'}
        className={'TitleAll PrintRemoveMargin'}
        children={translateOverview('overviewTitle')}
      />
      <ContentAll>
        <Card
          title={
            <Title
              tag={'h2'}
              className={'TitleWithIcon'}
              children={translateOverview('translationsTitle')}
            />
          }
          icon={<YIcon name="signLanguage" />}
        >
          <DataCard>
            <StyledMetric>{data.translations}</StyledMetric>
            <YHelpButton
              content={translateOverview('translationsContentPeriod', {
                period,
              })}
              direction={isMobile() === true ? 'left' : 'bottom'}
              context={'dark'}
              display={'desktop'}
              alignment={'center'}
            />
          </DataCard>
        </Card>
        <Card
          className="CardUsers"
          icon={<YIcon name={'accessibility'} />}
          title={
            <Title
              tag={'h2'}
              className={'TitleWithIcon'}
              children={translateOverview('ResourcesTitle')}
            />
          }
        >
          <DataCard>
            <StyledMetric>{data.addons}</StyledMetric>
            <YHelpButton
              content={translateOverview('ResourcesContent', { period })}
              direction={isMobile() === true ? 'left' : 'bottom'}
              context={'dark'}
              display={'desktop'}
              alignment={'center'}
            />
          </DataCard>
        </Card>
        <Card
          className="CardUsers"
          icon={<YIcon name="person" />}
          title={
            <Title
              tag={'h2'}
              className={'TitleWithIcon'}
              children={
                isAverageReport
                  ? translateOverview('usersAverageTitle')
                  : translateOverview('usersTitle')
              }
            />
          }
        >
          <DataCard>
            <StyledMetric>{data.users}</StyledMetric>
            <YHelpButton
              content={translateOverview('usersContent', { period })}
              direction={isMobile() === true ? 'left' : 'bottom'}
              context={'dark'}
              display={'desktop'}
              alignment={'center'}
            />
          </DataCard>
        </Card>
      </ContentAll>
    </CustomContainer>
  )
}

export default Overview
