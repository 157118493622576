import { injectable } from 'inversify'

@injectable()
export default class Config {
  public get firebaseApiKey() {
    return process.env.REACT_APP_FIREBASE_API_KEY || '--'
  }

  public get ga4Tag() {
    return process.env.REACT_APP_GA4_TAG || '--'
  }

  public get firebaseProjectId() {
    return process.env.REACT_APP_FIREBASE_PROJECT_ID || ''
  }

  public get createAccountRedirect() {
    return process.env.REACT_APP_CREATE_ACCOUNT_REDIRECT || '--'
  }

  public get apiBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL || '--'
  }

  public get resetPasswordRedirect() {
    return process.env.REACT_APP_RESET_PASSWORD_REDIRECT || '--'
  }
}
