type BadgeProps = {
  className?: string
}

const BadgeOneHundredMillion: React.FC<BadgeProps> = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.4039 5.6776C34.0994 3.79742 4.76608 30.0828 2.88616 64.3873C1.56331 88.5262 14.1865 110.203 33.7363 121.586H96.2434C113.731 111.407 125.924 92.9096 127.114 71.1951C128.994 36.8908 102.708 7.55752 68.4039 5.6776Z"
        fill="#F5585C"
      />
      <path
        d="M83.5979 65.3872C83.3748 64.7954 83.3958 64.1393 83.6564 63.5631C83.917 62.9868 84.3958 62.5377 84.9875 62.3144C98.2904 57.2967 111.303 46.424 115.08 32.7755C117.552 23.8436 113.064 14.9777 103.749 14.9777C99.0547 14.9777 95.3357 18.9373 95.6622 23.6653C95.8351 26.1744 97.2199 28.504 99.3662 29.8962C100.471 30.6127 100.786 32.0897 100.069 33.1947C99.3525 34.2994 97.8755 34.615 96.7708 33.8972C93.373 31.6933 91.1798 27.9909 90.9043 23.9931C90.6552 20.3798 91.8734 16.9373 94.3348 14.3C100.475 7.7223 111.507 9.46181 116.874 16.5151C128.155 31.3419 112.133 57.1723 86.6712 66.7768C85.4374 67.2415 84.0628 66.6194 83.5979 65.3872Z"
        fill="#FAB941"
      />
      <path
        d="M96.7705 33.8972C97.8753 34.6148 99.3525 34.2994 100.069 33.1947C100.786 32.0897 100.471 30.6127 99.366 29.8962C97.2197 28.504 95.8351 26.1744 95.662 23.6653C95.5045 21.385 96.2713 19.2149 97.8209 17.5546C101.63 13.4738 108.245 14.708 111.722 17.9098C110.743 15.8057 112.857 13.5842 115.007 14.4556C109.498 9.34578 99.9347 8.30045 94.3343 14.3C91.8729 16.9371 90.6547 20.3798 90.9038 23.9931C91.1798 27.9909 93.3728 31.6933 96.7705 33.8972Z"
        fill="#F6A221"
      />
      <path
        d="M43.3291 66.7771C35.8523 63.9567 28.8785 59.6398 23.1618 54.2925C12.6153 44.4283 4.39156 27.9957 13.1264 16.5153C18.4892 9.4674 29.5207 7.71722 35.6654 14.3002C38.1268 16.9373 39.3451 20.38 39.096 23.9934C38.8205 27.9911 36.6272 31.6936 33.2292 33.8975C32.1247 34.615 30.6478 34.2999 29.931 33.1944C29.7605 32.9317 29.6435 32.638 29.5865 32.3301C29.5296 32.0221 29.5339 31.706 29.5991 31.3997C29.6644 31.0934 29.7894 30.803 29.9669 30.545C30.1444 30.287 30.371 30.0666 30.6338 29.8962C32.7801 28.5038 34.1649 26.1744 34.3378 23.6653C34.4952 21.385 33.7284 19.2149 32.1788 17.5546C28.1816 13.2722 20.5987 14.5714 16.9221 19.4033C14.3036 22.8452 13.5548 27.8441 14.9195 32.7757C16.6974 39.1991 20.6741 45.4352 26.4197 50.8097C31.6098 55.6639 38.2126 59.7497 45.0122 62.3144C45.5965 62.5431 46.0671 62.9928 46.3221 63.566C46.5771 64.1393 46.5959 64.7899 46.3745 65.3769C46.1531 65.964 45.7093 66.4401 45.1392 66.7022C44.5692 66.9643 43.9188 66.9912 43.3291 66.7771Z"
        fill="#FAB941"
      />
      <path
        d="M59.2193 96.7576C60.6516 96.069 62.5389 93.6239 62.5389 85.5197H57.7649C57.7649 93.6236 52.2166 96.0687 48.0061 96.7576C45.3172 97.1976 43.357 99.5432 43.357 102.268V104.15H57.6377C57.656 103.961 57.3379 97.6622 59.2193 96.7576Z"
        fill="#F6A221"
      />
      <path
        d="M81.994 96.7576C77.7834 96.069 72.2351 93.6239 72.2351 85.5197H67.4611C67.4611 93.6236 69.3484 96.0687 70.7807 96.7576C72.652 97.6577 72.345 103.973 72.362 104.15H86.643V102.268C86.643 99.5429 84.6828 97.1973 81.994 96.7576Z"
        fill="#FAD964"
      />
      <path
        d="M73.6704 104.15V102.268C73.6704 99.5429 72.8851 97.1973 71.808 96.7573C70.1213 96.0687 67.8983 93.6236 67.8983 85.5194H62.1014C62.1014 93.6234 59.8787 96.0685 58.1918 96.7573C57.1144 97.1973 56.3294 99.5429 56.3294 102.268V104.15H73.6704ZM100.49 7.94904H29.5095C42.4805 17.7277 38.0854 35.3255 38.0854 53.3376C38.0854 73.8951 55.8375 78.2067 55.8375 78.2067H74.1625C74.1625 78.2067 91.9146 73.8951 91.9146 53.3376C91.9143 35.3255 87.5192 17.7275 100.49 7.94904Z"
        fill="#FAB941"
      />
      <path
        d="M55.8342 53.3376C55.8342 35.3255 58.9794 17.7275 49.6973 7.94904H29.5095C42.4805 17.7277 38.0854 35.3255 38.0854 53.3376C38.0854 73.8951 55.8375 78.2067 55.8375 78.2067H68.5374C68.5374 78.2067 55.8342 73.8948 55.8342 53.3376Z"
        fill="#F6A221"
      />
      <path
        d="M92.6359 103.514H37.3638C35.2419 103.514 33.522 105.234 33.522 107.356V121.459C41.8052 126.326 51.3416 129.343 61.5959 129.905C74.2828 130.6 86.2878 127.44 96.4775 121.449V107.356C96.4778 105.234 94.7578 103.514 92.6359 103.514Z"
        fill="#AB3829"
      />
      <path
        d="M52.9481 107.356C52.9481 105.234 54.668 103.514 56.7899 103.514H37.3641C35.2422 103.514 33.5222 105.234 33.5222 107.356V121.459C39.4248 124.927 45.9664 127.45 52.9481 128.82V107.356Z"
        fill="#9A2423"
      />
      <path
        d="M74.1625 86.1555H55.8378C53.4671 86.1555 51.5453 84.2337 51.5453 81.8629C51.5453 79.4922 53.4671 77.5704 55.8378 77.5704H74.1625C76.5332 77.5704 78.455 79.4922 78.455 81.8629C78.455 84.2337 76.5329 86.1555 74.1625 86.1555Z"
        fill="#FAD964"
      />
      <path
        d="M63.5972 81.8632C63.5972 79.4925 65.519 77.5706 67.8897 77.5706H55.8378C53.4671 77.5706 51.5453 79.4925 51.5453 81.8632C51.5453 84.2339 53.4671 86.1557 55.8378 86.1557H67.8897C65.5187 86.1555 63.5972 84.2339 63.5972 81.8632Z"
        fill="#FAB941"
      />
      <path
        d="M100.933 0H29.0667C26.696 0 24.7741 1.92182 24.7741 4.29254C24.7741 6.66326 26.696 8.58508 29.0667 8.58508H100.933C103.304 8.58508 105.226 6.66326 105.226 4.29254C105.226 1.92182 103.304 0 100.933 0Z"
        fill="#FAD964"
      />
      <path
        d="M46.4174 4.29254C46.4174 1.92182 48.3389 0 50.7096 0H29.0667C26.696 0 24.7741 1.92182 24.7741 4.29254C24.7741 6.66326 26.696 8.58508 29.0667 8.58508H50.7096C48.3389 8.58482 46.4174 6.66326 46.4174 4.29254Z"
        fill="#FAB941"
      />
      <path
        d="M45.0123 62.3144C38.2126 59.7497 31.6098 55.6636 26.4197 50.8094C20.6741 45.4352 16.6974 39.1991 14.9195 32.7755C12.6806 24.685 16.1759 16.2584 24.326 15.1125C22.0924 14.4922 21.7651 11.4486 23.8294 10.3718C12.7326 11.8407 7.26782 23.0092 10.3226 34.0473C12.3348 41.3179 16.7743 48.3181 23.1616 54.2923C28.8783 59.6395 35.8521 63.9564 43.3289 66.7768C44.5616 67.241 45.9367 66.6197 46.4019 65.3869C46.8668 64.1552 46.2447 62.7793 45.0123 62.3144Z"
        fill="#F6A221"
      />
    </svg>
  )
}

export default BadgeOneHundredMillion
