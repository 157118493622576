import React from 'react'

/** Hooks */
import useUserInfo from '../../hooks/useUserInfo'
import useAuth from '../../hooks/useAuth'
import useProtected from '../../hooks/useProtected'

/** Components */
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DataSection from '../../components/DataSection'
import LoadingWrapper from '../LoadingWrapper'
import LanguageSwitcher from '../../components/LanguageSwitcher'
import Menu from '../../components/Menu'

const DomainList: React.FC = () => {
  const { authState, signOut } = useAuth()
  const { checkAuthentication } = useProtected()
  const { userInfoState, fetchUserInfo } = useUserInfo()

  checkAuthentication()

  React.useEffect(() => {
    if (authState.type === 'success') {
      fetchUserInfo()
    }
  }, [authState, fetchUserInfo])

  const isLoading =
    userInfoState.type === 'loading' || authState.type === 'loading'

  let accessKeys: string[] = []
  let domains: string[] = []

  if (userInfoState.type === 'success') {
    accessKeys = userInfoState.userInfo.reports.map(
      (report) => report.accessKey.value
    )
    domains = userInfoState.userInfo.reports.map((report) => report.info.domain)
  }

  return (
    <LoadingWrapper loading={isLoading}>
      <Header.Navbar>
        <LanguageSwitcher />
        {authState.type === 'success' && (
          <Menu.Profile onClickLogout={signOut} />
        )}
      </Header.Navbar>
      <DataSection accessKeys={accessKeys} domains={domains} />
      <Footer />
    </LoadingWrapper>
  )
}

export default DomainList
