/** Libs */
import React from 'react'
import { useTranslation } from 'react-i18next'

/** Hooks */
import { usePreferencies } from '../../hooks/usePreferencies'

/** Services */
import { Language } from '../../services/interfaces'

/** Icons */
import BrCircleFlag from '../Icons/BrCircleFlag'
import UsCircleFlag from '../Icons/UsCircleFlag'

/** Styles */
import { Bar, IconStyled } from './styles'

const LanguageSwitcher: React.FC = () => {
  const { language, setLanguage } = usePreferencies()
  const { t: translationsLanguageSwitcher } = useTranslation('LanguageSwitcher')

  const toggleLanguage = () => {
    const newLanguage =
      language === Language['en-ase']
        ? Language['ptBR-bzs']
        : Language['en-ase']
    setLanguage(newLanguage)
  }

  return (
    <>
      <IconStyled
        tabIndex={0}
        onClick={toggleLanguage}
        role="button"
        aria-label={translationsLanguageSwitcher('title')}
        title={translationsLanguageSwitcher('title')}
      >
        {language === Language['en-ase'] ? <BrCircleFlag /> : <UsCircleFlag />}
      </IconStyled>
      <Bar />
    </>
  )
}

export default LanguageSwitcher
