/** Libs */
import styled from 'styled-components'

/** Tokens */
import { neutral } from '../../assets/tokens/colors'

export const Bar = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${neutral.color.high.lightest};
  margin: 0 10px;
`

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 44px;
  height: 44px;

  svg {
    width: 24px;
    height: 24px;
  }
`
