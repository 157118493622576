// Interfaces
import { ILoadingWrapper } from '../../services/interfaces'

// Components
import Loading from '../../components/Loading'
import Container from '../../components/Container'

const LoadingComponent: React.FC = () => (
  <>
    <Container>
      <Loading />
    </Container>
  </>
)

const LoadingWrapper: React.FC<ILoadingWrapper> = ({ loading, children }) => {
  return loading ? <LoadingComponent /> : <>{children}</>
}

export default LoadingWrapper
