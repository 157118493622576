//libs
import { inject, injectable } from 'inversify'

// Apis
import DashboardApi from '../apis/DashboardApi'

// Types
import {
  IPeriod,
  IReportSchema,
  Language,
  Report,
} from '../services/interfaces'

@injectable()
export default class ReportRepository {
  @inject(DashboardApi) dashboardApi: DashboardApi

  public getMonthly = async (accessKey: string, language: Language) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.')

    const report = await this.dashboardApi.get<IReportSchema>('getReport', {
      accessKey,
      language,
    })

    return report
  }

  public getYearly = async (accessKey: string) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.')

    const report = await this.dashboardApi.get<IReportSchema>(
      'getYearlyReport',
      {
        accessKey,
      }
    )

    return report
  }

  public filter = async (
    accessKey: string,
    period: IPeriod,
    language: Language
  ) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.')

    const report = await this.dashboardApi.get<Report>('filterReport', {
      ...period,
      accessKey,
      language,
    })

    return report
  }
}
