// Libs
import { useCallback, useState } from 'react'
import { YButton, YIcon, YInput } from '@hand-talk/yotta-components'
import { IconNames } from '@hand-talk/yotta-components/dist/components/YIcon'
import { useTranslation } from 'react-i18next'

//Styles
import { ErrorLabel, Form, PasswordTip, PasswordTipGroup } from './styles'

// Components
import HeaderForm from '../HeaderForm'

export interface IFirstAccessProps {
  onSubmit?: (password: string) => void
  error?: boolean
  titleText?: string
}

interface IPasswordValidation {
  icon: IconNames
  state: 'default' | 'success' | 'error'
}

const buildDefaultValidation = (): IPasswordValidation => ({
  icon: 'info',
  state: 'default',
})

const buildValidation = (result: boolean): IPasswordValidation => ({
  icon: result ? 'confirm' : 'close',
  state: result ? 'success' : 'error',
})

const initialValidation: Record<string, IPasswordValidation> = {
  shouldPasswordLenght: buildDefaultValidation(),
  shouldPasswordCaseSentitive: buildDefaultValidation(),
  shouldPasswordContainsNumber: buildDefaultValidation(),
  shouldPasswordSpecialChars: buildDefaultValidation(),
  shouldPasswordsEquals: buildDefaultValidation(),
}

export default function CreatePassword({
  onSubmit,
  error,
  titleText,
}: IFirstAccessProps) {
  const { t } = useTranslation('AuthForms')
  const [passwordValidation, setPasswordValidation] =
    useState(initialValidation)

  const hasPasswordError = Object.values(passwordValidation).some(
    (item) => item.state === 'error'
  )
  const hasConfirmPasswordError =
    passwordValidation['shouldPasswordsEquals'].state === 'error'

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault()

      const password = event.target['password'].value as string
      const confirmPassword = event.target['confirmPassword'].value as string

      const validation: Record<string, IPasswordValidation> = {
        shouldPasswordLenght: buildValidation(
          password.length >= 8 && password.length <= 30
        ),
        shouldPasswordCaseSentitive: buildValidation(
          /[a-z]/gm.test(password) && /[A-Z]/gm.test(password)
        ),
        shouldPasswordContainsNumber: buildValidation(/[0-9]/gm.test(password)),
        shouldPasswordSpecialChars: buildValidation(
          /[-+*/@#$%*()_+,.?]/gm.test(password)
        ),
        shouldPasswordsEquals: buildValidation(confirmPassword === password),
      }

      setPasswordValidation(validation)
      const canSubmit = Object.values(validation).every(
        (item) => item.state === 'success'
      )

      if (canSubmit) onSubmit?.(password)
    },
    [onSubmit]
  )

  return (
    <>
      <HeaderForm title={titleText} />
      <Form onSubmit={handleSubmit}>
        <YInput
          labelText={t('password')}
          error={error || hasPasswordError}
          inputProps={{
            name: 'password',
            placeholder: t('passwordPlaceholder'),
            type: 'password',
            required: true,
          }}
        />
        <YInput
          labelText={t('confirmPassword')}
          error={error || hasConfirmPasswordError}
          inputProps={{
            name: 'confirmPassword',
            placeholder: t('confirmPasswordPlaceholder'),
            type: 'password',
            required: true,
          }}
        />
        <PasswordTipGroup>
          {Object.entries(passwordValidation).map(([key, value]) => (
            <PasswordTip key={key} state={value.state}>
              <YIcon name={value.icon} />
              {t(key)}
            </PasswordTip>
          ))}
        </PasswordTipGroup>
        {error && (
          <ErrorLabel aria-live="assertive" role="alert">
            {t('createPasswordError')}
          </ErrorLabel>
        )}
        <YButton style={{ marginTop: 15 }} size="total">
          {t('createPassword')}
        </YButton>
      </Form>
    </>
  )
}
