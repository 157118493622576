// Libs
import { YIcon, YLink } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

// Components
import HeaderForm from '../HeaderForm'
import { emailCS } from '../../services/constants'
import { Content } from './styles'

export default function Expired() {
  const { t } = useTranslation('AuthForms')

  return (
    <>
      <HeaderForm title={t('expiredTitle')} error />
      <Content>
        {t('expiredInstructions')}
        <YLink
          icon={<YIcon name="externalLink" />}
          iconPosition="right"
          href={emailCS}
          rel="noreferrer"
        >
          cs@handtalk.me
        </YLink>
      </Content>
    </>
  )
}
