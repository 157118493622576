// Libs
import React from 'react'
import { YButton, YIcon, YLink } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

// Styles
import { Container, StyledListItem } from './styles'

// Components
import CustomContainer from '../CustomContainer'
import Title from '../Title'

// Services
import { formatDomain } from '../../services/helper'
import * as Analytics from '../../services/analytics'

type DataSectionProps = {
  accessKeys: string[]
  domains: string[]
}

const DataSection: React.FC<DataSectionProps> = ({ accessKeys, domains }) => {
  const { t: translationsDataSection } = useTranslation('DataSection')

  const handleDomainLinkClick = async (domain: string) => {
    await Analytics.clickOnDomainLinksEvent(domain)
  }

  const handleDashboardAccess = async (key: string, domain: string) => {
    await Analytics.dashboardAccessEvent(domain)
    window.location.href = `/${key}`
  }

  return (
    <CustomContainer>
      <Container>
        <Title
          tag={'h2'}
          className={'TitleDomainList'}
          children={translationsDataSection('titleDataSection')}
        />
        <ul>
          {accessKeys.map((key, index) => (
            <StyledListItem key={index}>
              <YLink
                href={formatDomain(domains[index])}
                size="big"
                target="_blank"
                onClick={() => handleDomainLinkClick(domains[index])}
              >
                {domains[index]}
              </YLink>
              <YButton
                icon={<YIcon name="arrowRight" />}
                iconPosition="right"
                size="fitContent"
                variation="text"
                onClick={() => handleDashboardAccess(key, domains[index])}
              >
                {translationsDataSection('accessText')}
              </YButton>
            </StyledListItem>
          ))}
        </ul>
      </Container>
    </CustomContainer>
  )
}

export default DataSection
