// Libs
import { YIcon, YMenuItem } from '@hand-talk/yotta-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonOverride, MenuContainer, MenuOverride } from './Styles'

interface IMenuExportProps {
  onClickExportCsv?: () => void
  onClickExportPdf?: () => void
}

export default function Export({
  onClickExportCsv,
  onClickExportPdf,
}: IMenuExportProps) {
  const [open, setOpen] = useState<boolean>()
  const [isPrinting, setIsPrinting] = useState<boolean>()

  const { t } = useTranslation('Menu')

  const handleClickPdf = async () => {
    setOpen(false)
    setIsPrinting(true)

    setTimeout(() => {
      onClickExportPdf?.()
      setIsPrinting(false)
    }, 100)
  }

  const handleClickCsv = () => {
    setOpen(false)
    onClickExportCsv?.()
  }

  return (
    <MenuContainer>
      {!isPrinting && (
        <ButtonOverride
          className={'ButtonOverride'}
          icon={<YIcon name="download" />}
          iconPosition="left"
          variation="tonal"
          aria-label={t('exportButtonLabel')}
          onClick={() => setOpen(true)}
        >
          {t('exportText')}
        </ButtonOverride>
      )}
      {open && (
        <MenuOverride
          onClose={setOpen}
          fluid={true}
          position={{ top: '53px', right: '0px' }}
          zIndex={2}
        >
          <YMenuItem icon={<YIcon name="download" />} onClick={handleClickPdf}>
            {t('exportPDF')}
          </YMenuItem>
          {onClickExportCsv && (
            <YMenuItem
              icon={<YIcon name="download" />}
              onClick={handleClickCsv}
            >
              {t('exportCSV')}
            </YMenuItem>
          )}
        </MenuOverride>
      )}
    </MenuContainer>
  )
}
