// Libs
import styled from 'styled-components'

// Services
import { tabletMediaQuery } from '../../services/helper'

export const Container = styled.div`
  display: flex;

  > section:last-child {
    margin-left: 16px !important;
  }

  ${tabletMediaQuery(`
    flex-direction: column;

    > section:last-child {
        margin-left: 0 !important;
      }
  `)}
`
