/** Libs */
import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Styles */
import {
  ButtonStyle,
  ContainerStyle,
  IconWrapper,
  StyledTag,
  StyledTitle,
} from './styles'

type FilterButtonProps = {
  period: string
  onFilter: (period: string) => void
  showCalendar: boolean
  periodReport: string
  tagReport: string
}

const FilterButton: React.FC<FilterButtonProps> = ({
  period,
  onFilter,
  showCalendar,
  periodReport,
  tagReport,
}) => {
  const { t: translateFilter } = useTranslation('Filter')

  const handleClick = () => {
    onFilter(period)
  }

  const tag =
    tagReport === 'monthlyAverage'
      ? translateFilter('monthlyAverage')
      : translateFilter('standard')
  return (
    <ContainerStyle>
      <StyledTitle>
        <YIcon name="filter" />
        <span>{translateFilter('title')}</span>
      </StyledTitle>

      <ButtonStyle
        isOpen={showCalendar}
        title={translateFilter('labelButton')}
        aria-label={translateFilter('labelButton')}
        onClick={handleClick}
      >
        <StyledTag>{tag}</StyledTag>
        {periodReport}
        <IconWrapper isOpen={showCalendar}>
          <YIcon name="triangleRight" />
        </IconWrapper>
      </ButtonStyle>
    </ContainerStyle>
  )
}

export default FilterButton
