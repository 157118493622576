/* NEUTRAL COLORS */
export const neutral = {
  color: {
    low: {
      main: '#333333',
      lightest: '#979797',
      light: '#818181',
      medium: '#666666',
      dark: '#000000',
    },
    high: {
      main: '#f5f5f5',
      lightest: '#ffffff',
      light: '#dEdEdE',
      medium: '#c4c4c4',
      dark: '#aeaeae',
    },
  },
}

/* BRAND COLORS */
export const brand = {
  color: {
    main: '#f06f06',
    lightest: '#fbc5a0',
    light: '#faae4b',
    medium: '#fb8c01',
    dark: '#cc5e05',
    new: '#e56700',
  },
}

/* HIGHLIGHT COLORS */
export const highlight = {
  color: {
    main: '#413987',
    lightest: '#b6c0ff',
    light: '#5b69c2',
    medium: '#542dc2',
    dark: '#121a5c',
    new: '#0c2a45',
  },
}

/* FEEDBACK COLORS */
export const feedback = {
  color: {
    sucess: {
      main: '#20c6ad',
      light: '#ccfcde',
      medium: '#00e9b4',
      dark: '#006666',
    },
    info: {
      main: '#3b98d2',
      lightest: '#9be1ff',
      light: '#dcfcfe',
      medium: '#48c1f8',
      dark: '#103c75',
    },
    alert: {
      main: '#fcc002',
      lightest: '#fcf6b2',
      light: '#fcfa37',
      medium: '#fbd24f',
      dark: '#ca9a00',
    },
    error: {
      main: '#ff4d4d',
      lightest: '#ffb3b3',
      light: '#ff8080',
      medium: '#ff6666',
      dark: '#b23535',
    },
  },
}

/* ACCESSIBILITY COLORS */
export const accessibility = {
  color: {
    main: '#003087',
  },
}

/* TRANSPARENT BACKGROUND */
export const transparent = {
  color: {
    main: '#ff000000',
  },
}

export const filter = {
  color: {
    light: 'rgba(51, 51, 51, 0.3)',
    dark: 'rgba(245, 245, 245, 0.3)',
  },
}
