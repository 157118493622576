/** Interfaces */
import { ITitle } from '../../services/interfaces'

const Title: React.FC<ITitle> = (props) => {
  /**
   * @param as tag HTML
   * @returns Hierarquia de titulos html
   */
  const TitleComponent = <C extends React.ElementType>({
    as,
    children,
    className,
  }: {
    as?: C
    children: React.ReactNode
    className: string
  }) => {
    const Component = as || 'h1'

    return <Component className={className}>{children}</Component>
  }

  return (
    <TitleComponent className={props.className} as={props.tag}>
      {props.children}
    </TitleComponent>
  )
}
export default Title
