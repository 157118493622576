/** Libs */
import React from 'react'
import { useTranslation } from 'react-i18next'

/** Hooks */
import usePreferencies from '../../hooks/usePreferencies'

/** Styles */
import { LoadingStyled, Text } from './styles'

/** Images */
import AnimatedLoading from '../../assets/svg/AnimatedLoading'
import i18n from '../../i18n'

const Loading = () => {
  const { t: translationLoading } = useTranslation('Loading')
  const { oralLanguage } = usePreferencies()

  React.useEffect(() => {
    i18n.changeLanguage(oralLanguage)
  }, [oralLanguage])

  return (
    <LoadingStyled>
      <AnimatedLoading />
      <Text role="status" aria-live="assertive">
        {translationLoading('loadingText')}
      </Text>
    </LoadingStyled>
  )
}

export default Loading
