// Libs
import { inject, injectable } from 'inversify'

// Domain
import DashboardApi from '../../apis/DashboardApi'

import UserRepository from '../../repositories/UserRepository'

@injectable()
export default class CreateUserPasswordUseCase {
  @inject(DashboardApi) private dashboardApi: DashboardApi
  @inject(UserRepository) private userRepository: UserRepository

  public async execute(token: string, email: string, password: string) {
    await this.dashboardApi.post(
      'createUserPassword',
      { password },
      { Authorization: token }
    )
    await this.userRepository.signIn(email, password)
  }
}
