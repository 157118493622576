/** Libs */
import styled from 'styled-components'

/** Services */
import { mobileMediaQuery } from '../../services/helper'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
  box-shadow: ${({ theme }) => theme.shadow[3]};
`
export const Container = styled.div`
  display: flex;

  ${mobileMediaQuery(`
    flex-direction: column;
    padding-bottom: 16px;
  `)}
`
