/** Libs */
import React from 'react'
import { useParams } from 'react-router-dom'

/** Services */
import * as Analytics from '../../services/analytics'

/** Components */
import LoadingWrapper from '../LoadingWrapper'
import Header from '../../components/Header'
import TopContainerRetrospective from '../../components/TopContainerRetrospective'
import Resume from '../../components/Resume'
import Sharing from '../../components/Sharing'
import Timeline from '../../components/Timeline'
import Footer from '../../components/Footer'

/** Hooks */
import useReport from '../../hooks/useReport'

const Retrospective: React.FC = () => {
  const { accessKey } = useParams()
  const { reportYearly, isLoading, getYearlyReport } = useReport()

  // Verifica se o report foi carregado
  React.useEffect(() => {
    if (!reportYearly) return

    Analytics.accessCounterEvent(reportYearly.info.domain)
  }, [reportYearly])

  // Carrega o report quando o componente é montado
  React.useEffect(() => {
    if (!accessKey) return

    getYearlyReport(accessKey)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey])

  return (
    <LoadingWrapper loading={isLoading}>
      {!isLoading && reportYearly && (
        <>
          <Header.Navbar />
          <TopContainerRetrospective />
          <div className="RainbowLoader" />
          <Resume reportYearly={reportYearly} />
          <Sharing />
          <Timeline />
          <Footer />
        </>
      )}
    </LoadingWrapper>
  )
}

export default Retrospective
