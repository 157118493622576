interface ITranslations {
  [key: string]: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export const Translations: ITranslations = {
  en: {
    topAddons: {
      'Contraste de cores': 'Color contrast',
      'Leitor de sites': 'Website reader',
      'Modo de leitura': 'Reading mode',
      'Máscara de leitura': 'Reading mask',
      'Guia de leitura': 'Reading guide',
      'Espaço entre linhas': 'Line spacing',
      'Espaço entre letras': 'Letter spacing',
      'Tamanho da fonte': 'Font size',
      'Destaque de links': 'Highlight link',
      Saturação: 'Saturation',
      Fonte: 'Font',
      'Lupa de conteúdo': 'Content magnifier',
    },
  },
}

export const translationsHelper = (
  str: string,
  category: string,
  language: string
): string => {
  const categoryTranslations = Translations[language]?.[category]
  return categoryTranslations?.[str] || str
}
