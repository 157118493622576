import { OptionInput, OptionLabel, OptionSelector, RadioGroup } from './styles'

export interface IRadioOption {
  value: 'standard' | 'monthlyAverage'
  label: string
  title: string
}

interface IRadioProps {
  options: IRadioOption[]
  selectedOption: IRadioOption
  name: string
  onChange?: (option: IRadioOption) => void
}

const Radio = ({ options, selectedOption, onChange, name }: IRadioProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const option = options.find((option) => option.value === inputValue)

    if (option && onChange) {
      onChange(option)
    }
  }

  return (
    <RadioGroup>
      {options.map(({ label, value, title }) => (
        <div key={value}>
          <OptionInput
            name={name}
            type="radio"
            id={value}
            value={value}
            checked={selectedOption.value === value}
            onChange={handleChange}
            aria-label={title}
            title={title}
          />
          <OptionLabel htmlFor={value} title={title}>
            <OptionSelector checked={selectedOption.value === value} />
            {label}
          </OptionLabel>
        </div>
      ))}
    </RadioGroup>
  )
}

export default Radio
