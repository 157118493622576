import styled from 'styled-components'

/** Tokens */
import { font } from '../../assets/tokens/fonts'
import { highlight } from '../../assets/tokens/colors'

export const Text = styled.p`
  font: ${font.subtitle.small};
  font-family: ${font.family};
  color: ${highlight.color.new};
  text-align: center;
  margin-bottom: 12px;
`
