// Libs
import React from 'react'

// Interfaces
import { Timestamp } from '../../services/Timestamp'

// Functions
import { useDatepickerRange } from './useDatepickerRange'
import { useHighlightedMode } from './useHighlightedMode'
import { useSet } from '../YCalendar/utils/useSet'
import { useCalendarMonth } from '../YCalendar/utils/useCalendarMonth'

// Components
import { CalendarDay } from '../YCalendar/CalendarDay'
import { Calendar } from '../YCalendar/Calendar'

export type YDatepickerRangeProps = Parameters<typeof useDatepickerRange>[0]

const YDatepickerRange: React.FC<YDatepickerRangeProps> = (props) => {
  const { selected, onDisableDay } = props

  const monthTimestamp = selected?.[0] ? selected[0] : (Date.now() as Timestamp)

  const { currentMonthTimestamp, onPrevMonth, onNextMonth } =
    useCalendarMonth(monthTimestamp)

  const { nextSelected, highlightedDay, onClick, onHighlight } =
    useDatepickerRange(props)

  const nextSelectedSet = useSet(nextSelected)
  const getHighlightedMode = useHighlightedMode(nextSelected, highlightedDay)

  return (
    <Calendar
      monthTimestamp={currentMonthTimestamp}
      onPrevMonth={onPrevMonth}
      onNextMonth={onNextMonth}
    >
      {(day) => (
        <CalendarDay
          isAverageReport={props.isAverageReport}
          key={String(day.timestamp)}
          highlightedMode={getHighlightedMode(day.timestamp)}
          isSelected={nextSelectedSet.has(day.timestamp)}
          isCurrentMonth={day.isSameMonth}
          dayTimestamp={day.timestamp}
          onClick={onClick}
          onPointerEnter={onHighlight}
          onDisable={onDisableDay}
        >
          {day.displayDay}
        </CalendarDay>
      )}
    </Calendar>
  )
}

const DatepickerRangeMemo = React.memo(YDatepickerRange)
export { DatepickerRangeMemo as YDatepickerRange }
