// Libs
import { useCallback } from 'react'
import { YInput, YButton, YLink } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

// Components
import HeaderForm from '../HeaderForm'

// Style
import { Form, ErrorLabel } from './styles'

export interface ISignInProps {
  onSubmit?: (email: string, password: string) => void
  error?: boolean
}

export default function SignIn({ onSubmit, error }: ISignInProps) {
  const { t } = useTranslation('AuthForms')

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault()

      const email = event.target['email'].value as string
      const password = event.target['password'].value as string

      onSubmit?.(email, password)
    },
    [onSubmit]
  )

  return (
    <>
      <HeaderForm title={t('joinTitle')} />
      <Form onSubmit={handleSubmit}>
        <YInput
          labelText={t('email')}
          error={error}
          inputProps={{
            name: 'email',
            placeholder: t('emailPlaceholder'),
            type: 'email',
            required: true,
          }}
        />
        <YInput
          labelText="Senha"
          error={error}
          inputProps={{
            name: 'password',
            placeholder: t('passwordPlaceholder'),
            type: 'password',
            required: true,
          }}
        />
        <YLink inline href="/reset-password">
          {t('forgotPassword')}
        </YLink>
        {error && (
          <ErrorLabel aria-live="assertive" role="alert">
            {t('invalidCredentialsError')}
          </ErrorLabel>
        )}
        <YButton style={{ marginTop: 15 }} size="total">
          {t('join')}
        </YButton>
      </Form>
    </>
  )
}
