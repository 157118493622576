import styled from 'styled-components'
import SmallDataCard from '../SmallDataCard'

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`
export const SmallDataCardStyled = styled(SmallDataCard)`
  @media (max-width: 400px) {
    flex-direction: column;
  }
`
