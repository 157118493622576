// Libs
import { useCallback, useState } from 'react'
import { useInjection } from 'inversify-react'

// Services
import AuthRepository from '../repositories/UserRepository'

interface IResetPasswordResult {
  type: 'loading' | 'success' | 'none'
}

interface IResetPasswordError {
  type: 'error'
  message: string
}

type ResetPasswordState = IResetPasswordError | IResetPasswordResult

export default function useResetPassword() {
  const authRepository = useInjection(AuthRepository)

  const [resetPasswordState, setResetPasswordState] =
    useState<ResetPasswordState>({
      type: 'none',
    })

  const resetPassword = useCallback(
    async (email: string) => {
      try {
        setResetPasswordState({ type: 'loading' })
        await authRepository.resetPassword(email)
        setResetPasswordState({ type: 'success' })
      } catch (error) {
        const { message } = error as Error
        setResetPasswordState({ type: 'error', message })
      }
    },
    [authRepository]
  )

  const confirmResetPassword = useCallback(
    async (oobCode: string, newPassword: string) => {
      try {
        setResetPasswordState({ type: 'loading' })
        await authRepository.confirmResetPassword(oobCode, newPassword)
        setResetPasswordState({ type: 'success' })
      } catch (error) {
        const { message } = error as Error
        setResetPasswordState({ type: 'error', message })
      }
    },
    [authRepository]
  )

  return { resetPassword, resetPasswordState, confirmResetPassword }
}
