/** Libs */
import styled from 'styled-components'

/** Tokens */
import { font } from '../../assets/tokens/fonts'

interface IText {
  size: number
  color: string
  height: number
  weight: number
  colorPrint?: string
}

const CustomText = styled.p<IText>`
  font-size: ${({ size }) => size}px;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
  line-height: ${({ height }) => height}px;
  font-family: ${font.family};

  @media print {
    color: ${({ colorPrint }) => colorPrint};
  }
`
export default CustomText
