import React from 'react'

import { checkInRange } from '../../services/utils'
import { Timestamp } from '../../services/Timestamp'

export const useHighlightedMode = (
  nextSelected: Timestamp[],
  highlightedDay: Timestamp
) => {
  const [fromDay, toDay] = React.useMemo(
    () => [...nextSelected, highlightedDay].slice(0, 2),
    [nextSelected, highlightedDay]
  )

  return (dayTimestamp: Timestamp): 'from' | 'to' | 'middle' | null => {
    const inRange = checkInRange(dayTimestamp, [fromDay, toDay].sort())

    if (!inRange || toDay === fromDay) {
      return null
    }

    if (toDay < fromDay) {
      if (dayTimestamp === toDay) {
        return 'from'
      }

      return dayTimestamp === fromDay ? 'to' : 'middle'
    }

    if (dayTimestamp === toDay) {
      return 'to'
    }

    return dayTimestamp === fromDay ? 'from' : 'middle'
  }
}
