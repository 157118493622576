// Libs
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Hooks
import useAuth from './useAuth'

const useProtected = () => {
  const { authState } = useAuth()
  const navigate = useNavigate()

  const checkAuthentication = useCallback(() => {
    if (authState.type === 'none') {
      navigate('/')
    }
    return authState.type === 'success'
  }, [authState, navigate])

  return { checkAuthentication }
}

export default useProtected
