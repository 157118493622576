// Libs
import styled from 'styled-components'

// Funcions
import { getIconStyle } from '../../services/helper'

// Tokens
import { font } from '../../assets/tokens/fonts'
import { spacing } from '../../assets/tokens/spacings'
import { highlight, neutral, feedback } from '../../assets/tokens/colors'
import { border } from '../../assets/tokens/borders'

export interface IStyledInputProps {
  error?: boolean
}

export const Container = styled.div`
  position: absolute;
`

export const ContainerStyle = styled.div`
  box-sizing: border-box;
  position: relative !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: ${spacing.nano};
  background: ${neutral.color.high.lightest};
  border: 1px solid ${neutral.color.low.light};
  border-radius: ${border.radius.md};
  overflow: hidden;
  font: ${font.paragraph.medium};
  font-family: ${font.family};

  &:hover {
    border-color: ${highlight.color.light};
  }

  &:focus {
    border-color: ${highlight.color.light};
  }

  &[data-error='true'] {
    border-color: ${feedback.color.error.dark};
  }

  &[data-focus-visible='true'] {
    border-color: ${highlight.color.main};
  }

  &[data-disabled='true'] {
    pointer-events: 'none';
    background: ${neutral.color.high.main};
    border-color: ${neutral.color.low.light};
    color: ${neutral.color.low.light};

    & > button {
      pointer-events: none;
      background: ${neutral.color.low.light};
    }
  }
`

export const Label = styled.span`
  font: ${({ theme }) => theme.typography.label.smBold};
  color: ${({ theme }) => theme.colors.surface.onSurface};
`

export const StyledInput = styled.input<IStyledInputProps>`
  box-sizing: border-box;
  position: relative !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0px 16px;

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background: ${neutral.color.high.main};
    border-color: ${neutral.color.low.light};
    color: ${neutral.color.low.light};
  }
`

export const Small = styled.small`
  font: ${font.paragraph.Xsmall};
  font-family: ${font.family};
  color: ${feedback.color.error.main};
  margin-bottom: ${spacing.nano};
  display: flex;
  align-items: center;
`

export const IconStyle = styled.span`
  ${getIconStyle('16px', feedback.color.error.main)};
  margin-right: 4px;
`

export const ErrorMessage = styled.span`
  color: ${feedback.color.error.dark};
  padding: 0 16px;
`
export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 16px 16px 0;
  border-top: 1px solid ${neutral.color.high.medium};
`

export const Buttons = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid ${neutral.color.high.medium};
`
