// Core
import TYPES from '../../core/Types'

// Domain
import DecodeAuthTokenUseCase from '../../domain/use-cases/DecodeAuthTokenUseCase'

// Main
import { container } from '../../inversify.config'

export default function useDecodeAuthTokenUseCase() {
  return container.get(TYPES.DecodeAuthTokenUseCase) as DecodeAuthTokenUseCase
}
